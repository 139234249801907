//import logo from './logo.svg';
//import './App.css';
// import React, { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import './style.css'

import {Routes, Route} from "react-router-dom";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Dashboard from './components/Dashboard';
import TransactionNew from './components/TransactionNew'
import Dashchart from "./components/Dashchart";  
// import Chartnew from "./components/Chartnew";
import AccountNew from './components/AccountNew';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';

import Layout from './components/Layout';


import Customers from './components/Customers';
import Customer from './components/Customer';
import CustomerNew from './components/CustomerNew';
import CustomerEdit from './components/CustomerEdit';

import Statement from './components/Statement';
import Bookings from './components/Bookings';
import BookingNew from './components/BookingNew';
import CheckIn from './components/CheckIn';
import CheckOut from './components/Checkout';


import Leads from './components/Leads';
import LeadNew from './components/LeadNew';

import Properties from './components/Properties';
import Property from './components/Property';
import PropertyNew from './components/PropertyNew';
import RateNew from './components/RateNew';
import UnitTypeNew from './components/UnitTypeNew';
import Units from './components/Units';
import Unit from './components/Unit';
// import RoomDetail from './components/RoomDetail';
import UnitNew from './components/UnitNew';
import Accounts from './components/Accounts';
import Transactions from './components/Transactions';
import Agents from './components/Agents';
import AppSettings from './components/AppSettings';
import Join from './pages/join';

import AgentNew from './components/AgentNew';
import Lead from './components/Lead';
import MonthlyReport from './reports/MonthlyReport';

// import useToken from './components/useToken';

import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material';
import OccupancyReport from './reports/OccupancyReport';



//Override MUI global settings
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       textTransform : 'non'
  //     }
  //   }
  // }
})

function App() {
  // useAxiosInterceptor();

  // localStorage.removeItem("accessToken"); 
  // localStorage.removeItem("refreshToken"); 

  
  // const { token, setToken } = useToken();
  // const [token, setToken] = useState([])
  // console.log("token1", token)

  // console.log("WE ARE HERE");

  // const acctkn = localStorage.getItem('accessToken')
  // const [token, setToken] = useState(acctkn)
  // console.log("TOKEN", token)
  // console.log("AT", acctkn)

  // if(!token) {
  //   // return <Login setToken={setToken} />
  //   // console.log("WE ARE STILL HERE");
  //   return <Login />
  // }


  return (
    
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
          <Routes>
            <Route path="/" element={ <Home/> } />
            <Route path="/join" element={ <Join/> } />  
            <Route path="/login" element={ <Login/> } />  
            <Route path="/forgot" element={ <ForgotPassword/> } />  
            <Route path="/reset" element={ <ResetPassword/> } />  
            <Route path="/password/reset/:uid/:token" element={ <ResetPassword/> } />  
            <Route path="/contact" element={ <Contact/> } />  

            {/* <Route path="/dashboard" element={ <Dashboard/> } />  */}
            <Route path="/dashboard" element={ <Layout/> } > 

              <Route path="business-intelligence" element={ <Dashboard/> } /> 

              <Route path="settings" element={ <AppSettings /> } /> 

              <Route path="properties" element={ <Properties/> } /> 
              <Route path="propertynew" element={ <PropertyNew/> } /> 
              <Route path="property/:id" element={ <Property/> } />  
              <Route path="unittypenew" element={ <UnitTypeNew/> } />  
              <Route path="units" element={ <Units/> } />  
              <Route path="unit/:id" element={ <Unit/> } />  
              <Route path="unitnew" element={ <UnitNew/> } />  
              <Route path="bookings" element={ <Bookings/> } />  
              <Route path="bookingnew" element={ <BookingNew/> } />  
              <Route path="checkin/:id" element={ <CheckIn/> } />  
              <Route path="checkout/:id" element={ <CheckOut/> } />  


              <Route path="ratenew" element={ <RateNew/> } />  
              {/* <Route path="/roomdetail/:id" element={ <RoomDetail/> } />   */}

              <Route path="leads" element={ <Leads/> } />  
              <Route path="lead/:id" element={ <Lead/> } />  
              <Route path="leadnew" element={ <LeadNew/> } />  
              <Route path="customers" element={ <Customers/> } />  
              <Route path="customer/:id" element={ <Customer/> } />  
              <Route path="customernew" element={ <CustomerNew/> } />  
              <Route path="customeredit/:id" element={ <CustomerEdit/> } />  

              <Route path="statement/:id" element={ <Statement/> } />  

              <Route path="agents" element={ <Agents/> } />  
              <Route path="agentnew" element={ <AgentNew/> } />  

              <Route path="transactions" element={ <Transactions/> } />  
              <Route path="transnew" element={ <TransactionNew/> } />  
              <Route path="dashchart" element={ <Dashchart/> } />  
              <Route path="accounts" element={ <Accounts/> } />  
              <Route path="accountnew" element={ <AccountNew/> } />  
              <Route path="monthlyreport" element={ <MonthlyReport/> } />  
              <Route path="occupancyreport" element={ <OccupancyReport/> } />  

            
            </Route>

          </Routes>
      </StyledEngineProvider>

    </ThemeProvider>


    );
}

export default App;
