
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";

// import axios from "axios";
import useAxios from "./useAxios";
import authAxios from "./authAxios";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent } from "@mui/material";
// import { Button } from "@mui/material";
import { useMediaQuery } from 'react-responsive'

// import ThreeDotsMenu from "./ThreeDotsMenu";
// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';

// import Pillchip from "./Pillchip";
import UnitTypeNew from "./UnitTypeNew";

// const UnitTypeList = ({unittypes, handleDrawerOpen, fetchUnitTypes}) => {

const UnitTypeList = ({unittypes, fetchUnitTypes, pagecount}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    // const navigate = useNavigate();
    const params = useParams();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [page, setPage] = useState(1);
    // const [pagecount, setPageCount] = useState();

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        console.log("DRAWER CLOSE")
        setIsDrawerOpen(false)
    };

    // FETCH PROPERTY UNIT TYPES 
    const { data, fetchData}  = useAxios();   

    // const [unittypes, setUnitTypes] = useState([]);

    // async function fetchUnitTypes () {
    //     try {
    //         const response = await authAxios.get(`/unittypes/?property=${params.id}`);
    //         setPageCount(response.data.page_count)
    //         setUnitTypes(Array.from(response.data.results))
    //         console.log("unittypes", unittypes)

    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    const handlePageChange = (event, value) => {
        setPage(value);
    };


    useEffect(() => {
        // if (!isDrawerOpen) {
            fetchUnitTypes()
        // }

    // },[isDrawerOpen, data.length])
    },[isDrawerOpen, page])


   
//    //Pagination
//    const [currentPage, setCurrentPage] = useState(1);
//    const [postsPerPage] = useState(5);

//     // Get current posts
//    const indexOfLastPost = currentPage * postsPerPage;
//    const indexOfFirstPost = indexOfLastPost - postsPerPage;
//    const currentPosts = unittypes.slice(indexOfFirstPost, indexOfLastPost);

//    // Change page
//    const paginate = pageNumber => setCurrentPage(pageNumber);

   const threeSubMenu = [
       {
           id: 1,
           menu: "New",
           url: "/unittype/",
       }, 
       // {
       //     id: 2,
       //     menu: "Booking",
       //     url: "/bookingnew/",
       // }
   ];

   
    return (
    <>
    
        {isMobile? 
        <>

        {unittypes && unittypes.map(unittype => ( 

        <Card className="dashboard-card" key={unittype.id}>

            <CardContent className="dashboard-card-content">
                <div className="dashboard-card-title">{unittype.id}</div>
                <div className="dashboard-card-subtitle">{unittype.name}</div>                            

            </CardContent>
            {/* <CardActions>
                <Button size="small">Booking</Button>
            </CardActions> */}
        </Card>
        ))}

        </>

        :
        // If Device is not mobile, display data using a table
        <table className="styled-table">

            <thead>
                <tr >
                    <th>ID</th>
                    <th>Description</th>
                    <th>No of Units</th>

                    {/* <th></th> */}
                </tr>
            </thead>
            {unittypes && unittypes.map(unittype => (


                <tbody key={unittype.id}>
                    <tr >   
                        <td data-label="ID">{unittype.id}</td>
                        <td data-label="Name">{unittype.name}</td>
                        <td data-label="No of Units">{unittype.units}</td>


                        {/* <td className='styled-table-menu'>
                            <ThreeDotsMenu data={unittype} threeSubMenu={threeSubMenu} />
                        </td> */}

                    </tr>

                </tbody>

            ))}
            
        </table>


        }

        <UnitTypeNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />

        <Pagination 
            className="custom-mui-pagination"
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        />

        <Fab 
            className="fab" 
            color="primary" 
            aria-label="add"
            onClick={()=>{
                // navigate('/unittypenew/',{state:{property:params.id}})
                handleDrawerOpen()
            }}
            >
            <AddIcon />
        </Fab>

    </>
    )

}

export default UnitTypeList;