// import axios from "axios";
import authAxios from "./authAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";
import { useState, useEffect } from "react";

// const useAxios = () => {
// const useAxios = axios.create({
// const useAxios = (axiosParams) => axios.create({

const useAxios = (axiosParams) => {    
  const {accToken } = useAxiosInterceptor();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState('');

  const fetchData = async (params) => {
  // async function fetchData (params) {

    try {
      setLoading(true); //Added later for testing

      // const response = await axios.request(params);
      const response = await authAxios.request(params); //use Axios Instance
      setData (response.data);
      setResponse (response);

      // console.log("USE AX RESPONSE", response)
      // console.log("USE AX DATA", data)
      // console.log("PARAMS", params)



    } catch( error ) {
        console.log("USE AXIOS ERROR",error)
        const status = error.response.status

        // if (status === 400){
        //   setError(error.request.responseText);
        // }
        // else if (status === 400 | status === 401){
        if (status === 400 | status === 401){
          setError(status +" " + error.response.statusText + " - " + error.response.request.response);
        }
        else if (status === 401){
          setError(status +" " + error.response.statusText + " - " + error.response.data.detail);
        }
        else {
          setError( error.message);
        }

    } finally {
      setLoading(false);
    }


  };


  useEffect(() => {
      fetchData(axiosParams);
  }, [accToken]); // Enables proper refresh when the access token expires
      
  return { response, data, error, loading, fetchData} ;



};

export default useAxios;


