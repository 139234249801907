import { useState, useEffect, memo } from "react";


import { Link } from "react-router-dom";
import useAxios from "../components/useAxios";
import { List } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
// import GroupIcon from '@mui/icons-material/Group';
import InventorySharpIcon from '@mui/icons-material/InventorySharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import QrCodeSharpIcon from '@mui/icons-material/QrCodeSharp';
import ShoppingBagSharpIcon from '@mui/icons-material/ShoppingBagSharp';
// import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import SpokeIcon from '@mui/icons-material/Spoke';

import HotelIcon from '@mui/icons-material/Hotel';
import SettingsIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';
import GroupIcon from '@mui/icons-material/Group';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from '@mui/icons-material/Dashboard';


import logo from '../assets/gesti-logo.svg'

// import logo from '../assets/zidi-logo.svg'

// import { useCountRenders } from "../hooks/useCountRenders";


const Sidebar = () => {
    // useCountRenders()

    // const [menuItems, setMenuItems] = useState([]);
    const [open, setOpen] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState([]);

    // const { data, fetchData}  = useAxios();   

    // const fetchMenu = () => {
    //     fetchData({
    //         url: "/saas/client-menu",
    //         method: 'get',
    //     })
    // }

    const handleClick = (index) => {
        const newOpen = [...open];
        newOpen[index] = !newOpen[index];
        setOpen(newOpen);
        setSelectedIndex(index);
        // console.log("NEWOPEN", newOpen)
        // console.log("INDEX", index)

    };
   
    // const handleClick = (index) => {
    //     setOpen((prevOpen) => {
    //       const newOpen = [...prevOpen];
    //       newOpen[index] = !newOpen[index];
    //       return newOpen;
    //     });
    //     setSelectedIndex(index);
    //     console.log("INDEX", index)

    //   };
      

   

    // useEffect(() => {
    //     fetchMenu()
    //     if (data.length){
    //         setMenuItems(data)
    //     }
    // },[data.length])

    const menuItems = [
        { 
            // id: '00100', 
            id: 1, 
            name: 'Dashboard', 
            icon: <AppsSharpIcon className="dashboard-navicon"/>, 
            path: '/dashboard/business-intelligence', 
            level:1
        },
        { 
            // id: '00200', 
            id: 2, 
            name: 'PMS', 
            icon: <HotelIcon className="dashboard-navicon"/>, 
            path: '/rooms#', 
            level:1,
            submenu: [
            {
                // id: '00210', 
                id: 2.1, 
                name: 'Properties', 
                path: '/dashboard/properties', 
                // level:2
            },
            {
                // id: '00220', 
                id: 2.2, 
                name: 'Units', 
                path: '/dashboard/units', 
                // level:2
            },
            {
                // id: '00230', 
                id: 2.3, 
                name: 'Bookings', 
                path: '/dashboard/bookings', 
                // level:2
            }
            ]
        },
        { 
            // id: '00300', 
            id: 3, 
            name: 'CRM', 
            icon: <GroupIcon className="dashboard-navicon"/>, 
            path: '/crm#', 
            level:1,
            submenu: [
                {
                    // id: '00310', 
                    id: 3.1, 
                    name: 'Leads', 
                    path: '/dashboard/leads', 
                    // level:2

                },
                {
                    // id: '00320', 
                    id: 3.2, 
                    name: 'Customers', 
                    path: '/dashboard/customers', 
                    // level:2

                },
                
                ]
        },
        { 
            // id: '00400', 
            id: 4, 
            name: 'Channels', 
            icon: <HubIcon className="dashboard-navicon"/>, 
            path: '/channels#', 
            level:1,
            submenu: [
                {
                    // id: '00410', 
                    id: 4.1, 
                    text: 'Agents', 
                    path: '/Agents', 
                    // level:2

                },
                
                ]
        },
        { 
            // id: '00500', 
            id: 5, 
            name: 'Accounting', 
            icon: <BarChartIcon className="dashboard-navicon"/>, 
            path: '/dashboard/accounting#', 
            level:1,
            submenu: [
                {
                    // id: '00510', 
                    id: 5.1, 
                    name: 'Chart of Accounts', 
                    path: '/dashboard/accounts', 
                    // level:2

                },
                {
                    // id: '00520', 
                    id: 5.2, 
                    name: 'Transactions', 
                    path: '/dashboard/transactions', 
                    // level:2

                },
                {
                    // id: '00530', 
                    id: 5.3, 
                    name: 'Reports', 
                    path: '/dashboard/monthlyreport', 
                    // level:2
                },
                
                ]
        },
        { 
            id: 6, 
            name: 'Reports', 
            icon: <SettingsIcon className="dashboard-navicon"/>, 
            path: '/reports#',
            level:1,
            level:1,
            submenu: [
                {
                    id: 6.1, 
                    name: 'Monthly Report', 
                    path: '/dashboard/monthlyreport', 

                },
                {
                    id: 6.2, 
                    name: 'Occupancy Report', 
                    path: '/dashboard/occupancyreport', 
                },
            ]
        },
        { 
            id: 7, 
            name: 'Settings', 
            icon: <SettingsIcon className="dashboard-navicon"/>, 
            path: '/settings',
            level:1

        }
       
    ];

   

    const SingleLevel = ({item}) => {
        // console.log("SINGLE LEVEL", item)

        return(
            <ListItemButton 
                // key={item.id}
                selected={selectedIndex === item.id}
                // selected={selectedIndex === index}

                onClick={() => handleClick(item.id)}
                // onClick={() => handleClick(index)}
                className="dashboard-navlink" 
                component={Link} to={item.path}
            >
                {/* <MenuIcon item={item} /> */}
                {item.icon}

                <ListItemText primary={item.name} className="dashboard-navlink-text"/>
            </ListItemButton>
        )
    }

    const MultiLevel = ({item}) => {
        // console.log("MULTI LEVEL", item)
        // console.log("Open state for", item.name, ":", open[item.id]);
        return(
            <>
            <ListItemButton 
                // key={item.id} 
                selected={selectedIndex === item.id}
                // selected={selectedIndex === index}

                className="dashboard-navlink" 
                onClick={() => handleClick(item.id)}>
                {/* onClick={() => handleClick(index)}> */}

                {/* <MenuIcon item={item} /> */}
                {item.icon}

                <ListItemText primary={item.name} className="dashboard-navlink-text"/>
                {open[item.id] ? <ExpandLess /> : <ExpandMore />}
                {/* {open[index] ? <ExpandLess /> : <ExpandMore />} */}

            </ListItemButton>
            {/* <Collapse in={open[index]} timeout="auto" unmountOnExit> */}

            <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/* {menuItems.map((item1) => (  */}
                    {item.submenu.map((item1) => ( 
                        <div key={item1.id}>
                            {/* {item1.parent_menu===item.menu?  */}
                            <>
                                {/* {item1.has_submenu?  */}

                                {item1.submenu? 
                                    <>
                                    <ListItemButton  selected={selectedIndex === item1.id} className="dashboard-submenu-navlink" onClick={() => handleClick(item1.id)}>
                                    {/* <ListItemButton  selected={selectedIndex === item1.id} className="dashboard-submenu-navlink" onClick={() => handleClick(index)}> */}
                                        <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
                                        {open[item1.id] ? <ExpandLess /> : <ExpandMore />}
                                        {/* {open[index] ? <ExpandLess /> : <ExpandMore />} */}
                                    </ListItemButton>
                                    <Collapse in={open[item1.id]} timeout="auto" unmountOnExit>
                                    {/* <Collapse in={open[index]} timeout="auto" unmountOnExit> */}
                                        <List component="div" disablePadding>
                                        {menuItems.map(item2 => (
                                            <div key={item2.id}>
                                                {item2.parent_menu===item1.menu?
                                                    <ListItemButton 
                                                        selected={selectedIndex === item2.id}
                                                        className="dashboard-submenu-navlink" 
                                                        onClick={() => handleClick(item2.id)}
                                                        // onClick={() => handleClick(index)}

                                                        component={Link} to={item2.path}
                                                    >
                                                        <ListItemText primary={item2.name} className="dashboard-submenu-text"/>
                                                    </ListItemButton>    
                                                    : 
                                                    null   
                                                }
                                            </div>
                                                               
                                        ))}
                                        </List>
                                    </Collapse>
                                    </>
                                :
                                <ListItemButton 
                                    key={item1.id} 
                                    selected={selectedIndex === item1.id}
                                    // selected={selectedIndex === index1}
                                    className="dashboard-submenu-navlink" 
                                    component={Link} to={item1.path}
                                    onClick={() => handleClick(item1.id)}
                                    // onClick={() => handleClick(index)}
                                    >
                                    <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
                                </ListItemButton>
                                }
                            </>
                            {/* :
                            null} */}
                        
                        </div>

                    ))} 
                </List>
            </Collapse>
        </>

        )

    }
    
    return(


    <section className="dashboard-menu flex-column">
        <div className="dashboard-brand">
            <Link to="/">
                <img src={logo} alt="brand logo"/>  
            </Link>

        </div>

        <List >
            {menuItems.map((item) => (
                item.level===1? 
                    // item.has_submenu?
                    item.submenu?

                    <MultiLevel item={item} key={item.id} />
                    :
                    <SingleLevel item={item} key={item.id} />
                : 
                null
            ))}

        </List>


    </section>
    )


}

// export default Sidebar;
export default memo(Sidebar);