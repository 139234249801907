import React, { useEffect } from "react"
import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import useAxios from "./useAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import Pagination from './Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'

import NumberFormat from "react-number-format";


// const Transactions = ({ txns, loading }) => {
const Statement = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    const params = useParams()

    // console.log("searchparams",searchParams)
    // console.log("params",params)

    //Axios
    // const { data: txns, loading } = useAxios('/statement')
    const { data: txns, loading } = useAxios(`/statement/?customer=${params.id}`)
    const {response, error } = useAxiosInterceptor();

    // const [sumInvoices, setSumInvoices] = useState(0)
    let sumInvoices = 0
    let sumReceipts = 0
    let balance = 0
    let customer = ""
        
    const calcStats = () => {

        // const [sumInvoices, setSumInvoices] = useState(0)
        for(let i= 0; i < txns.length; i++){
            // const invoice = sumInvoices
            // console.log(txns[i].type)
            if (txns[i].type===10){
                sumInvoices = sumInvoices +  parseFloat(txns[i].amount)
            }
            if (txns[i].type===20){
                sumReceipts = sumReceipts +  parseFloat(txns[i].amount)
            }
            customer = txns[i].customer_name

            // setSumInvoices(invoice+txns[i].amount)
            // console.log("INV",txns[i].amount)
        }
        balance = sumInvoices - sumReceipts
    }
    calcStats()

    // console.log("sum invoices", sumInvoices)
    // console.log("sum receipts", sumReceipts)


     //Pagination
    // const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
  
     // Change page
     const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">

                    {/* <DashboardHeader dashboardTitle="Statement" dashboardPreTitle="Statement" /> */}
                    <DashboardHeader dashboardTitle={customer} dashboardPreTitle="Statement" />


                    <div className="dashboard-content-subheader">
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Invoices
                            </div>
                            <div className="dashboard-card-text">
                                {/* <NumberFormat 
                                    // onChange={(e) => setAmt(e.target.value)} 
                                    // className="custom-input"
                                    // fullWidth
                                    // label="Amount" 
                                    // variant="outlined" 
                                    // customInput={TextField}
                                    // format="254 (###) ######" mask="_"
                                    // thousandSeparator={true} 
                                    // prefix={'KES '} 
                                    // required
                                /> */}
                                KES {sumInvoices.toLocaleString()}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Receipts
                            </div>
                            <div className="dashboard-card-text">
                                KES {sumReceipts.toLocaleString()}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Balance
                            </div>
                            <div className="dashboard-card-text">
                                KES {balance.toLocaleString()}
                            </div>
                        </div>

                    </div>

                    {isMobile? 
                    <>
                    
                    {txns && txns.map(txn => ( 

                    <Card className="dashboard-card" key={txn.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{txn.amount}</div>
                            <div className="dashboard-card-subtitle">{txn.trans_type}</div>                            
                            <p>Reference: {txn.reference}</p>
                            <p>Account: {txn.account}</p>
                            <p>Date: {txn.date}</p>
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table

                    <table className="styled-table">

                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Reference</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Account</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>

                        {txns && txns.map(txn => (

                            <tbody key={txn.id}>
                                <tr>
                                    <td>{txn.id}</td>
                                    <td>{txn.reference}</td>
                                    <td>{txn.date}</td>
                                    <td>{txn.trans_type}</td>
                                    <td>{txn.account_name}</td>
                                    {/* <td>{txn.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td> */}
                                    <td>{Number(txn.amount).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td>

                                    <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td>

                                    {/* <td>icon={faEllipsisVertical}</td> */}
                                    {/* icon={faCoffee} */}
                                    {/* <td><FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /></td> */}
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    }
                

                    <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={txns.length}
                            paginate={paginate}
                        />

                    <Link to="/transnew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>

                </section>

            </div>

        </Layout>

    );
  };
  
  export default Statement;