import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxios from "./useAxios";

import { TextField } from "@mui/material";
// import Header from "./Header";
// import LayoutForm from "./LayoutForm";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import UserAlerts from "./UserAlerts";
import FormDrawer from "./FormDrawer";
// import Switch from "@mui/material";

const UnitTypeNew = ({isDrawerOpen, handleDrawerClose }) => {
    //Axios post hook
    const {error, response, fetchData}  = useAxios();

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    // const [activeTab, setActiveTab] = useState('unittypes');


    useEffect(() => {
        if (response.status === 201){
            // navigate('/properties/');
            // fetchUnitTypes()
            // refreshTypes()
            // setUnitTypeTab()
            handleDrawerClose()
            // navigate(`/property/${location.state.property}`);
        }    

    }, [ response ]); 

    // console.log("RESPONSE", response.status)


    const [name, setName] = useState('');
    // const property = location.state.property
    const property = params.id

    // console.log("prop", property)

    // const client = localStorage.getItem('clientID');

    const [nameError, setNameError] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (name) {
            const unittype = { property, name };

            // console.log("unittype", unittype)
        
            fetchData({
                  url: '/unittypes/',
                  method: 'post',
                  data: unittype
                })
        } 
    }

    return (
        // <Layout>

        // <div className="dashboard-main">
        <FormDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}>
    
            <section className="dashboard-form">
                <UserAlerts error={error} />

                <DashboardHeader dashboardTitle="Add Unit Type" dashboardPreTitle="Property Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <TextField className="custom-input"
                        helperText="Unit types include Room, Apartment, House"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)}
                        }
                        label="Name" 
                        variant="outlined" 
                        // color="secondary" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    {/* <FormGroup className="custom-input">
                        <FormControlLabel control={<Switch onChange={(e) => setMulti(e.target.value)} />} label="Multiple Units" />
                    </FormGroup>  */}

                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary" type="submit">
                            Save
                        </button>
                    </div>
                </form>
            </section>


        {/* </div>

        </Layout> */}
        </FormDrawer>


    )

}

export default UnitTypeNew;
