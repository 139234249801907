import React from "react";

// import axios from "axios";
import useAxios from "./useAxios";
import authAxios from "./authAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { FormHelperText } from "@mui/material";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";
import NumberFormat from "react-number-format";


// import { Slider } from "@mui/material";
// import { Typography } from "@mui/material";
// import { Alert } from "@mui/material";
// import { AlertTitle } from "@mui/material";
// import { Collapse } from "@mui/material";
// import { IconButton } from "@mui/material";

// import CloseIcon from '@mui/icons-material/Close';
// import { useFormControl } from '@mui/material/FormControl';

// Big Calendar Imports
// import { Calendar, dateFnsLocalizer } from "react-big-calendar";
// import format from "date-fns/format";
// import getDay from "date-fns/getDay";
// import parse from "date-fns/parse";
// import startOfWeek from "date-fns/startOfWeek";
// import { format } from 'date-fns'
// import { eachDayOfInterval } from 'date-fns'

// import moment from 'moment';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { DatePicker } from 'antd';
// import { Calendar } from 'antd';

// import Sidebar from "./Sidebar";
import UserAlerts from "./UserAlerts";
// import styles from "./CustomDatePicker.less"
// import LayoutForm from "./LayoutForm";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import FormDrawer from "./FormDrawer";

// function BookingNew() {
const BookingNew = ({isDrawerOpen, handleDrawerClose }) => {

    //Pass state variables from router
    const location = useLocation();
    // const [unit, setUnit] = useState(location.state.unit);
    const unit = location.state.unit;
    // const [property, setProperty] = useState(location.state.property);
    const property = location.state.property;


    //Axios

    //Get booked dates for this property
    const { data: bookings } = useAxios({
        url: '/bookings',
        method: 'get'
    })
    const propertyBookings = bookings.filter(function(booking) {
        return booking.unit === parseInt(unit);
        // return booking.unit === unit;
    });

    // Fetch Customers
    // const { data: customers } = useAxios({
    //     url: '/customers',
    //     method: 'get'
    // })
    // console.log("CUSTOMERS", customers)

    const [customers, setCustomers] = useState([]);

    async function fetchCustomers () {
        try {
            // const response = await authAxios.get(`/customers/?page=${page}`);
            const response = await authAxios.get("/customers");
            // setPageCount(response.data.page_count)
            setCustomers(Array.from(response.data.results))

        } catch (error) {
            console.error(error);
        }
    }

   

    // Fetch Booking Status
    const { data: bookingstatus } = useAxios({
        url: '/bookingstatus',
        method: 'get'
    })
   


    // Fetch Rates for active property
    const { data: rates } = useAxios({
        url: '/rates',
        method: 'get'
    })
    const propertyRates = rates.filter(function(propertyRate) {
        return propertyRate.property === property;
    });
    // console.log("PROPERTY RATES", propertyRates)


    //Axios Post Custom Hook
    const {error, response, fetchData}  = useAxios();
    // const { error, response } = useAxiosInterceptor();


    //Redirect back to Dashboard after successful post
    useEffect(() => {
        fetchCustomers()
        if (response.status === 201){
            // navigate('/bookings/');
            navigate(`/unit/${location.state.unit}`);
        }    

    }, [ response.status]); 



    
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD-MM-YYYY';
    const moment = extendMoment(Moment);



    
    // console.log(propertyBookings)

    const disabledDate = (current) => {
        // return current && current < moment().endOf('day');
        
        // for(let i= 0; i < bookings.length; i++){
        //     if ((current > moment(bookings[i].start)) && (current < moment(bookings[i].end))){
        //         return true;
        //     }
        // }
        for(let i= 0; i < propertyBookings.length; i++){
            if ((current > moment(propertyBookings[i].start)) && (current < moment(propertyBookings[i].end))){
                return true;
            }
        }
        
        if (current < moment().endOf('day')){
            return true
        }
      };

    
    const handleBookingDates = (range) => {
        setStart(moment(range[0]).format("YYYY-MM-DD"));
        setEnd(moment(range[1]).format("YYYY-MM-DD"));
        // console.log(range)

        // validateDateRange()
        let bookedRange = moment.range()
        const userRange = moment.range(moment(range[0]),moment(range[1]))

        // Reset Error
        setEndError(false)
        setDatesError("")

        // for(let i= 0; i < bookings.length; i++){
        for(let i= 0; i < propertyBookings.length; i++){
            // bookedRange = moment.range(bookings[i].start,bookings[i].end)
            bookedRange = moment.range(propertyBookings[i].start,propertyBookings[i].end)
            // console.log(bookedRange)

            if(bookedRange.overlaps(userRange)){
                setEndError(true)
                setDatesError("Invalid date range. Exclude blocked date(s)")
            }

        }
    }

    const handleRate=(e, range)=>{
        const d1 = moment(start);
        const d2 = moment(end)

        let duration;

        // console.log(duration)

        setRateError(false)
        setRate(e.target.value)
        // console.log("RATE",e.target.value)
        const selectedRate = propertyRates.find(propertyRate => propertyRate.id === e.target.value);
        console.log("RATE",selectedRate)
        if (selectedRate.type==="10"){
            if (selectedRate.frequency ==="10"){
                duration = d2.diff(d1,'days');
                console.log(duration, "Days")
                setAmount(selectedRate.amount*duration)
            }
            if (selectedRate.frequency ==="20"){
                // const d = d2.diff(d1,'days');
                duration = Math.ceil((d2.diff(d1,'days'))/7);
                console.log(duration, "Weeks")
                setAmount(selectedRate.amount*duration)
            }
            if (selectedRate.frequency ==="30"){
                duration = Math.ceil((d2.diff(d1,'days'))/30);
                console.log(duration, "Months")
                setAmount(selectedRate.amount*duration)
            }
                // setAmount(selectedRate.amount*duration)
            // console.log("AMT", amt, "AMOUNT", amount, "SEL RATE", selectedRate.amount)
        }
    }

    const navigate = useNavigate();

    const [amount, setAmount] = useState('');
    const [status, setStatus] = useState('');
    const [rate, setRate] = useState('');
    const [customer, setCustomer] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [memo, setMemo] = useState('');

    // const [user, setUser] = useState('1');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const [rateError, setRateError] = useState(false)
    const [customerError, setCustomerError] = useState(false)
    const [statusError, setStatusError] = useState(false)
    const [endError, setEndError] = useState(false)
    const [datesError, setDatesError] = useState('')


    const handleSubmit = (e) => {
        e.preventDefault();

        setCustomerError(false)

        // setDatesError(true)

        // if (reference === '') {
        //     setReferenceError(true)
        // }

        if (customer === '' ) {
            setCustomerError(true)
        }

        if (status === '' ) {
            setStatusError(true)
        }

        if (endError){
            document.getElementById("picker").scrollIntoView()
        }

        // if (reference && customer && status && endError===false) {

        if ( customer && status && endError===false) {
            // console.log(reference, customer)
    
            // const booking = { client, reference, unit, customer, status, start, end, memo, status, user };
            // const price = parseFloat(amount.replace(/,/g, ''));
            const price = amount;

            const booking = { client, unit, customer, status, start, end, rate, price, memo, user };

            console.log("booking:", booking)
         
            fetchData({
                url: '/bookings/',
                method: 'post',
                data: booking
              })
        } 
    
    }

    

    return(

        <Layout>

        <div className="dashboard-main">


            <section className="dashboard-form">

                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Booking" dashboardPreTitle="Property Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <FormControl                             
                            className = "custom-daterange-picker"
                            id="picker"
                            error={endError}
                        >
                            <RangePicker 
                                format={dateFormat}
                                onChange={handleBookingDates}
                                // className = "custom-daterange-picker"
                                disabledDate={disabledDate}
                            />
                            <FormHelperText>{datesError}</FormHelperText>
                        </FormControl>

                     
                        {/* <TextField className="custom-input"
                            onChange={(e) => {
                                setReferenceError(false)
                                setReference(e.target.value)}
                            }
                            label="Reference" 
                            variant="outlined" 
                            value="UY43UYT3"
                            fullWidth
                            required
                            error={referenceError}
                        /> */}

                        
                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            error={customerError}
                            required
                        >
                            <InputLabel>Customer</InputLabel>
                            <Select
                                className="custom-select"
                                label="Customer"
                                onChange={(e) => {
                                    setCustomerError(false)
                                    setCustomer(e.target.value)}
                                }
                                defaultValue = ""
                                // error={customerError}
                            >
                                {customers.map((customer) => { 
                                    return(
                                    <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                    )
                                })}   
                               
                            </Select>
                        </FormControl>

                        {/* <TextField className="custom-input"
                            onChange={(e) => {
                                // setReferenceError(false)
                                setStatus(e.target.value)}
                            }
                            label="Status" 
                            variant="outlined" 
                            // color="secondary" 
                            fullWidth
                            required
                            // error={referenceError}
                        /> */}


                        {/* <FormControl 
                            variant="outlined" 
                            fullWidth 
                            className="custom-input"
                            error={statusError}
                            required
                        >
                            <InputLabel>Status</InputLabel>
                            <Select
                                className="custom-select"
                                label="Status"
                                onChange={(e) => {
                                    setStatusError(false)
                                    setStatus(e.target.value)}
                                }
                                defaultValue = ""

                            >
                                {bookingstatus.map((status) => { 
                                    return(
                                        <MenuItem key={status.code} value={status.code}>{status.name}</MenuItem>
                                    )
                                })}       
                            </Select>
                        </FormControl> */}


                        <TextField
                            // id="filled-select-currency"
                            className="custom-input"
                            select
                            label="Status"
                            // value={currency}
                            // onChange={handleChange}
                            onChange={(e) => {
                                setStatusError(false)
                                setStatus(e.target.value)}
                            }                            
                            fullWidth
                            defaultValue = ""
                            error={statusError}
                            // helperText="An invoice will automatically be generated for CONFIRMED bookings"
                            >
                                {bookingstatus.map((status) => { 
                                    return(
                                        <MenuItem key={status.code} value={status.code}>{status.name}</MenuItem>
                                    )
                                })}      
                        </TextField>


                        <TextField
                            className="custom-input"
                            select
                            label="Rate"
                            // value={currency}
                            onChange={handleRate}
                            // onChange={(e) => {
                            //     // setStatusError(false)
                            //     setRate(e.target.value)}
                            // }                            
                            fullWidth
                            defaultValue = ""
                            error={rateError}
                            // variant="filled"
                            >
                                {propertyRates.map((propertyRate) => { 
                                    return(
                                        <MenuItem key={propertyRate.id} value={propertyRate.id}>{propertyRate.name} {propertyRate.amount} {propertyRate.frequency_name}</MenuItem>
                                    )
                                })}      
                        </TextField>      

                        <NumberFormat 
                            value={amount}
                            // onChange={(e) => setAmount(e.target.value)} 
                            className="custom-input"
                            fullWidth
                            label="Price" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            // prefix={'KES '} 
                        />  

                        <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                            // required
                            // error={detailsError}
                        /> 


                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary" type="submit">
                                Save
                            </button>
                        </div>

                </form>

            </section>
        
            </div>

    </Layout>    
    )
}

export default BookingNew;