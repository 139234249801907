import React from "react";
import { useState } from "react";

// import { Nav } from 'react-bootstrap';
import { List } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
// import { ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { StyledEngineProvider } from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import BarChartIcon from '@mui/icons-material/BarChart';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Link } from "react-router-dom";
// import logo from '../assets/stella-logo.svg'
import logo from '../assets/gesti-logo.svg'


function SidebarMobile() {
    const [open, setOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("");
 

    const handleClick = (menuKey) => {
        if (activeMenu && menuKey !== activeMenu && open){
            setOpen(true);
        }
        else{
            setOpen(!open);
        }
        setActiveMenu(menuKey);
        // console.log(item)

    };

    const menuItems = [
        { 
            text: 'Dashboard', 
            icon: <DashboardIcon className="dashboard-navicon"/>, 
            path: '/dashboard' 
        },
        { 
            text: 'PMS', 
            icon: <ApartmentIcon className="dashboard-navicon"/>, 
            path: '/rooms#', 
            submenu: [
            {
                text: 'Properties', 
                path: '/properties', 
            },
            {
                text: 'Units', 
                path: '/units', 
            },
            {
                text: 'Bookings', 
                path: '/bookings', 
            }
            ]
        },
        { 
            text: 'CRM', 
            icon: <GroupIcon className="dashboard-navicon"/>, 
            path: '/crm#', 
            submenu: [
                {
                    text: 'Leads', 
                    path: '/leads', 
                },
                {
                    text: 'Customers', 
                    path: '/customers', 
                },
                
                ]
        },
        { 
            text: 'Accounting', 
            icon: <BarChartIcon className="dashboard-navicon"/>, 
            path: '/accounting#', 
            submenu: [
                {
                    text: 'Chart of Accounts', 
                    path: '/accounts', 
                },
                {
                    text: 'Transactions', 
                    path: '/transactions', 
                },
                
                ]
        },
        { 
            text: 'Settings', 
            icon: <SettingsIcon className="dashboard-navicon"/>, 
            path: '/settings' 
        }
    ];


    return (           
        <>
        <StyledEngineProvider injectFirst>

        {/* <Nav className="dashboard-menu flex-column"> */}
        <section className="dashboard-menu flex-column">

            <div className="dashboard-brand">
                <Link to="/">
                    <img src={logo} alt="brand logo"/>  
                </Link>

            </div>

            <List >
                {menuItems.map((item) => (
                    item.submenu ?
                    <div key={item.text}>
                        <ListItemButton className="dashboard-navlink" onClick={() => handleClick(item.text)} >
                            {item.icon}
                            <ListItemText primary={item.text} className="dashboard-navlink-text"/>
                            {open && activeMenu === item.text ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        { activeMenu === item.text ?
                            <Collapse in={open} timeout="auto" unmountOnExit className="dashboard-navlink dashboard-collapse" > 
                                {item.submenu.map((submenu) => (
                                    <div key={submenu.text}>
                                        <List component="div" disablePadding key={submenu.text}>
                                            <ListItemButton className="dashboard-submenu-navlink" component={Link} to={submenu.path}>
                                                <ListItemText primary={submenu.text} className="dashboard-navlink-text"  />
                                            </ListItemButton>
                                        </List>
                                    </div>
                                ))}

                            </Collapse>
                            : 
                            null
                        }
                    </div>

                    :
                    <div key={item.text}>
                        <ListItemButton className="dashboard-navlink" component={Link} to={item.path}>
                            {item.icon}
                            <ListItemText primary={item.text} className="dashboard-navlink-text"/>
                        </ListItemButton>
                    </div>
                ))}
            </List>
        </section>

        </StyledEngineProvider>
        </>
    )

}
export default SidebarMobile;