// import React from 'react';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import { FormHelperText } from '@mui/material';
import { Button } from '@mui/material';

// import logo from '../assets/stella-logo.svg'
import logo from '../assets/gesti-logo.svg'
import CustomSnackBar from '../components/CustomSnackBar';
import UserAlerts from '../components/UserAlerts';


// import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FooterSlim from '../components/FooterSlim';
// import { green } from '@mui/material/colors';
// import Fab from '@mui/material/Fab';
// import CheckIcon from '@mui/icons-material/Check';
// import SaveIcon from '@mui/icons-material/Save';


function ForgotPassword() {
  // const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  // console.log("api", process.env.REACT_APP_API_ENDPOINT)


  let navigate = useNavigate();

  const [error, setError] = useState('');


  // function loginUser() {
  const [email, setEmail] = useState();
  // const [password, setPassword] = useState();
  // const [token, setToken] = useState([])
  // const [token, setToken] = useState()


  // useEffect(() => {
  //   console.log("TOKEN", token);
  // },[token]);

  const [emailError, setEmailError] = useState(false)

  const [response, setResponse] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    setEmailError(false)

    if (!email) {
      setEmailError(true)
    }
    
    if (email ) {
      const credentials = { email };
   
      console.log("credentials", credentials)
      setLoading(true)

      axios.post("/dj-rest-auth/password/reset/", credentials )
      // axios.post(`${API_ENDPOINT}/token/`, credentials )
        .then(res => {
          setResponse(res)
          setSuccess(true)
          setSuccessMessage("Password reset link has been sent to your email.")
        })
        .catch(function (error) {
          setLoading(false)

          if (error.response.status === 400 ){
            setError(error.message + " - " + error.response.request.response);
            }
            else if (error.response.status === 401){
            setError(error.response.status +" " + error.response.statusText + " - " + error.response.data.detail);
            }
            else {
            setError(error.response.status + " " + error.response.statusText);
            }
            // setError(error.message)
            // console.log("error", error.response);
            console.log("error", error);        
          });

      }
  }
  
  //Handle snackbar for success feedback to the user
  

  const handleSnackBarClose = () => {
    setLoading(false)
    setSuccess(false)
  };

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (response.status === 200 && success === false ){
        // navigate(from, { replace: true });
        navigate("/login/");
    }    
  }, [ response.status, success]); 

  return(
    // <div className="container">
    <div className="container-fluid">

      {/* <div className="container-auth"> */}
        {/* <div className="auth-img">
          <div className="auth-img-title">
            Forgot Password?
          </div>
          <div className="auth-img-subtitle">
            #1 Accommodation Management Platform
          </div>
        </div> */}
        <div className="auth-form">
          <UserAlerts error={error} />

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        
            <div className='auth-form-brand'>
              <img src={logo} alt="brand logo"/>  

            </div>

            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e => setEmail(e.target.value)}
                error={emailError}
              />

            <div className='auth-form-button'>
              <button className='btn btn-primary' type="Email Password Link">Email Password Reset Link</button>
              {
                loading && <CircularProgress />
              }

            </div>

          </form>
          <CustomSnackBar success={success} successMessage={successMessage} handleSnackBarClose={handleSnackBarClose}/>

        </div>

        <div className="footer-bg-lightblue">
          <FooterSlim />

        </div>

      </div>

  )
}

export default ForgotPassword