import axios from "axios";
// import useAxiosInterceptor from "./useAxiosInterceptor";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";


const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,

})



export default authAxios;