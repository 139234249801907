// import { Link } from "@mui/material"
import React from "react"
import hero from './../assets/hero.png'
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function Hero() {
    const navigate = useNavigate();

    return (
        <section className="hero">
            <div className="hero-title">
                #1 Software for Accommodation Management
            </div>
            <div className="hero-subtitle">
                gesti automates and streamlines every aspect of your growing and changing hospitality business including short term rentals, serviced apartments, hotels and lodges.
            </div>
            <button className="btn btn-primary" onClick={() => navigate("/contact")}>Learn More</button> 


            <div className="hero-body">               
                <div className="hero-img">

                    <img src={hero} alt="hero"/> 
                </div> 

            </div>
        </section>
    ) 
}

export default Hero