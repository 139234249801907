import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingUser, faChartLine, faUsers, faCalendarCheck  } from '@fortawesome/free-solid-svg-icons';
{/* <FontAwesomeIcon icon="fa-solid fa-calendar-circle-user" /> */}


function Features() {
    return (
        <section className="features">
            <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faCalendarCheck} />
                    <div className="features-card-title">Booking Engine</div>
                    <p className="card-text">With bookings coming in from so many different channels, it can be hard keeping track of them all. We help you to manage all bookings from a single, smart dashboard.</p>
                </div>
            </div>
            <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faUsers} />
                    <div className="features-card-title">CRM</div>
                    <p>Our technology empowers you to serve and manage more customers from one central hub, all while helping you build deeper guest relationships. </p>
                </div>
            </div>
            <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faBuildingUser} />
                    <div className="features-card-title">Property Management</div>
                    <p className="card-text">Whether you manage a single or multiple properties, grow and scale your business with our technology that make your life easier. </p>
                </div>
            </div>
            
            <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faChartLine} />
                    <div className="features-card-title">Accounting</div>
                    <p>Our best in class accounting solution handles double-entry book keeping, automated journal entries, owner accounting, accounts receivable and payable. </p>
                </div>
            </div>
        </section>
    ) 
}

export default Features