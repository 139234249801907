import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import Sidebar from "./Sidebar";
import { useMediaQuery } from 'react-responsive'

import ErrorBoundary from "../ErrorBoundary";

// function Layout ({children}) {
function Layout () {
    const location =  useLocation();

    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    return(
        <div className="dashboard-container">
            {isMobile? null: <Sidebar /> }
            {/* { children } */}
            <ErrorBoundary key={location.pathname}>
                <Outlet />
            </ErrorBoundary>
        </div>

    )
}

export default Layout;