import { useState, useEffect, useContext } from "react";
import useAxios from "../components/useAxios";
import {UserContext} from "../App"

import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DownloadIcon from '@mui/icons-material/Download';
import { Divider } from "@mui/material";


import Layout from "../components/Layout";
import DashboardHeader from "../components/DashboardHeader";
import OccupancyFilter from "./OccupancyFilter";

const OccupancyReport = () => {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    // const page_size = useContext(UserContext);
    const { data, fetchData}  = useAxios();   

    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    
    //FETCH ACCOUNT BALANCES
    const [occupancy, setOccupancy] = useState([]);
    const fetchOccupancy = () => {
        fetchData({
            url: "/occupancy-report",
            method: 'get',
        })
    }

    useEffect(()=>{
        handleDrawerOpen()
        // fetchOccupancy()
        // if (data.length){
        //     setOccupancy(data)
        // }
    },[data.length])


    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <div className="dashboard-report-header"> 

                        <div className="dashboard-report-title"> 
                            <DashboardHeader removeBorder dashboardTitle="Occupancy Report" dashboardPreTitle="Reports" />
                        </div>

                        <div className="dashboard-report-menu"> 
                            <DownloadIcon color="primary"/>
                          
                            <Button 
                                size="small" 
                                variant="outlined" 
                                startIcon={<FilterListIcon />}
                                onClick={handleDrawerOpen}
                                className="dashboard-report-menu-item"
                                >
                                Filter
                            </Button>

                        </div>

                    </div>

                    <Divider className="dashboard-divider" />


                        {/* <div className="dashboard-report-table"> */}
                            <table >
                                <thead>
                                    <tr>
                                        <th>Unit</th>
                                        <th>Stay Count</th>
                                        <th>Occupancy Rate</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {occupancy && occupancy.map(unit => (
                                        <tr key={unit.unit}>
                                            <td>{unit.unit}</td>
                                            <td>{unit.stay_count}</td>
                                            <td>{unit.occupancy_rate}%</td>
                                        </tr>
                                        
                                    
                                    ))}
                                </tbody>
                            </table>
                        {/* </div> */}

                    <OccupancyFilter isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} module={module}/>
                   

                </section>
            </div>
        // </Layout>
    );
};

export default OccupancyReport;