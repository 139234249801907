import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef, useCallback } from 'react';



// import DashboardHeader from "../components/DashboardHeader";
import DashboardHeader from "../src/components/DashboardHeader"


const ErrorPage = () => {
    return (
        <div className="dashboard-main">
            <section className="dashboard-content">
                {/* <DashboardHeader dashboardTitle="Error" dashboardPreTitle="Accounting"  /> */}
                <DashboardHeader dashboardPreTitle="Error"  />

 
                <div className='dashboard-error'>
                    <div className='error-header'>Ooooops!</div>
                    <h6>Sorry, something went wrong!</h6>
                    <p>Have you tried refreshing this page? If error persists, please let us know and we will resolve asap.</p>

                </div> 

            </section>
            
        </div>
    )
}

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      console.log("ERROR", error)
      console.log("INFO", info)
    }
  
    render() {
      if (this.state.hasError) {
        return (
            <ErrorPage/>
        );
      }
  
      return this.props.children;
    }
  }

  export default ErrorBoundary;

