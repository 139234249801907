import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import useAxios from "./useAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
// import Pagination from './Pagination';
import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent, TablePagination } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'
import FormDrawer from "./FormDrawer";
import TransactionNew from "./TransactionNew";
import CustomPagination from "./CustomPagination";


// const Transactions = ({ transactions, loading }) => {
const TransactionList = ({module, transactions, fetchTransactions, handlePageChange, page, pagecount}) => {

    // console.log(activeTab)
    // if (loading) {
    //   return <h2>Loading...</h2>;
    // }
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

 
    useEffect(()=>{
        fetchTransactions()
 
    },[isDrawerOpen])


    //Pagination
    // const [page, setPage] = React.useState(2);
  
    // const handleChange = (event, value) => {
    //     setPage(value);
    //     console.log("PAGE", page)
    //     fetchTransactions(page)
    //   };

    // ORIGINAL PAGINATION
    // // const [loading, setLoading] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(10);
  
    // // Change page
    // const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>

        {isMobile? 
            <>
            
            {transactions && transactions.map(transaction => ( 

            <Card className="dashboard-card" key={transaction.id}>
                
                <CardContent className="dashboard-card-content">
                    <div className="dashboard-card-title">{transaction.amount}</div>
                    <div className="dashboard-card-subtitle">{transaction.trans_type}</div>                            
                    <p>Reference: {transaction.reference}</p>
                    <p>Account: {transaction.account}</p>
                    <p>Date: {transaction.date}</p>
                    {/* <Pillchip label={booking.status_name} /> */}
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Booking</Button>
                </CardActions> */}
            </Card>
            ))}

            </>

            :
            // If Device is not mobile, display data using a table
            <>

            <table className="styled-table">
                <thead>
                    <tr >
                        {/* <th>ID</th> */}
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Type</th>
                        {/* <th>Description</th> */}
                        <th>Account</th>

                        <th>Amount</th>
                        <th>DR/CR</th>
                        <th></th>
                    </tr>
                </thead>

                {transactions && transactions.map(transaction => (


                    <tbody key={transaction.id}>
                        <tr>
                            {/* <td>{transaction.id}</td> */}
                            <td>{transaction.reference}</td>
                            <td>{transaction.date}</td>
                            <td>{transaction.trans_type}</td>
                            {/* <td>{transaction.memo}</td> */}

                            <td>{transaction.account_name}</td>
                            {/* <td>{transaction.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td> */}
                            <td>{Number(transaction.amount).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td>
                            <td>{transaction.entry_type}</td>
                            <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td>

                            {/* <td>icon={faEllipsisVertical}</td> */}
                            {/* icon={faCoffee} */}
                            {/* <td><FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /></td> */}
                        </tr>
                    </tbody>
                ))}

                
            </table>

            </>

        }

        {/* <TablePagination
            component="div"
            count={100}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
            
        <TransactionNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} module={module}/>
        <Pagination 
            className="custom-mui-pagination"
            // color="primary" 
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        />

        {/* <Pagination
            postsPerPage={postsPerPage}
            totalPosts={transactions.length}
            paginate={paginate}
        /> */}

        <Fab 
            className="fab" 
            color="primary" 
            aria-label="add"
            onClick={()=>{
                handleDrawerOpen()
            }
            }
        >
            <AddIcon />
        </Fab> 

        </>

    );
  };
  
  export default TransactionList;