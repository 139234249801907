import React from "react";
import { useState } from "react";
// import axios from "axios";
import useAxios from "./useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

import { Link } from "react-router-dom";

import Pagination from "./Pagination";
// import Pillchip from "./Pillchip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "./DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";
import Layout from "./Layout";
import ThreeDotsMenu from "./ThreeDotsMenu";


function Properties() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })


    //Axios
    // const { data: bookings, loading } = useAxios('/bookings')
    // const {response, error } = useAxiosInterceptor();
    const { data: properties, loading } = useAxios({
        url: '/properties',
        method: 'get'
    })

    // console.log("Properties",properties)

    //Pagination
    // const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);

     // Get current posts
     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const currentPosts = properties.slice(indexOfFirstPost, indexOfLastPost);
 
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);


    // const setProfileTab = () => {
    //     setActiveTab("profile")
    // }

    // THREE DOTS COMPONENT
    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/property/",
        }, 
        // {
        //     id: 2,
        //     menu: "New",
        //     url: "/propertnewy/",
        // }
    ];

    return (
        // <Layout>

        <div className="dashboard-main">

            <section className="dashboard-content">
                <DashboardHeader dashboardTitle="Properties" dashboardPreTitle="Property Management" />

                {isMobile? 
                    <>
                    
                    {properties && properties.map(property => ( 

                    <Card className="dashboard-card" key={property.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{property.name}</div>
                            <div className="dashboard-card-subtitle">{property.address}</div>                            
                            {/* <p>Start: {booking.start}</p>
                            <p>End: {booking.end}</p>
                            <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table


                <table className="styled-table">
                    <thead>
                        <tr >
                            <th>ID</th>
                            <th>Name</th>
                            <th>Address</th>
                            {/* <th>Multi Unit</th> */}
                            <th>No of Units</th>

                            <th></th>
                        </tr>
                    </thead>

                    {properties && properties.map(property => (

                        <tbody key={property.id}>
                            <tr>
                                <td>{property.id}</td>
                                <td>{property.name}</td>
                                <td>{property.address}</td>
                                {/* <td>{property.multi}</td> */}
                                <td>{property.units}</td>

                                {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td> */}
                                <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={property} threeSubMenu={threeSubMenu} />
                                </td>
                            </tr>
                        </tbody>
                    ))}
                    

                </table>
                }

                <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={properties.length}
                    paginate={paginate}
                />

                <Link to="/dashboard/propertynew" className="nav-link">
                    <Fab className="fab" color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </Link>
            </section>
        </div>
        // </Layout>


    )
}
export default Properties