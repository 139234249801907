import React from "react";
import { useState } from "react";
import MobileDrawer from "./MobileDrawer";
// import logo from '../assets/stella-logo.svg';
import logo from '../assets/gesti-logo.svg'

import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from 'react-responsive';
import { Divider } from "@mui/material";


function DashboardHeader ({dashboardTitle, dashboardPreTitle, removeBorder}){
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerClose = () => {
        // console.log("DRAWER CLOSE")
        setIsDrawerOpen(false)
    };

    return(
        <>
        {isMobile? 
            // <div className="dashboard-mobile-navbar">
            <nav className="navbar">
    
    
                <div className="navbar-brand">
                {/* <div className="dashboard-brand"> */}
                    <img src={logo} alt="brand logo"/>  
                </div>
                
                <div className="navbar-icon">
                {/* <div className="dashboard-icon"> */}
                    <MenuIcon 
                        onClick={()=>setIsDrawerOpen(true)} 
                    />
                </div>
                <MobileDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}/>

            {/* </div> */}
            </nav>


            :null
            }

            <div className="dashboard-content-header">
                <h6 className="dashboard-content-pretitle">
                    {dashboardPreTitle}
                </h6>

                <h1 className="dashboard-content-title">
                    {dashboardTitle}

                </h1>
            </div>
            {removeBorder? null : <Divider className="dashboard-divider" />}

        </>

    )

}

export default DashboardHeader;