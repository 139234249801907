import React from "react";
import { useEffect, useState } from "react";

// import axios from 'axios';
import useAxios from "./useAxios";
import authAxios from "./authAxios";

import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import { TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

import NumberFormat from "react-number-format";

import Sidebar from "./Sidebar";
import UserAlerts from "./UserAlerts";
import DashboardHeader from "./DashboardHeader";
import FormDrawer from "./FormDrawer";


// function RateNew() {
const RateNew = ({isDrawerOpen, handleDrawerClose, unittypes, fetchUnitTypes}) => {
    const params = useParams()
    const location = useLocation();

    // console.log("LOCATION",location)
    const {error, response, fetchData}  = useAxios();


    // const property = location.state.property
    const property = params.id


    // Fetch Rate Frequencies 
    const { data: frequencies } = useAxios({
        url: '/ratefrequencies',
        method: 'get'
    })

    // Fetch Pricing Models 
    const { data: pricingmodels } = useAxios({
        url: '/pricingtypes',
        method: 'get'
    })


    //Redirect back to Dashboard after successful post
    const navigate = useNavigate();

    useEffect(() => {
        fetchUnitTypes()
        if (response.status === 201){
            // navigate(`/property/${location.state.property}`);
            handleDrawerClose()
        }    
    }, [response]); 


    // const moment = extendMoment(Moment);

    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [unitType, setUnitType] = useState('');
    const [frequency, setFrequency] = useState('');

    const [amt, setAmt] = useState('');
    const [memo, setMemo] = useState('');

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    // console.log(user,client,property)

    const [amtError, setAmtError] = useState(false);
    const [unitTypeError, setUnitTypeError] = useState(false);
    const [frequencyError, setFrequencyError] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [typeError, setTypeError] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();

        setAmtError(false)
        // setTypeError(false)

        if (name === "" ) {
            setNameError(true)
        }

        if (type === "" ) {
            setTypeError(true)
        }

        if (amt <=0 ) {
        // if (amt === 0 ) {
            setAmtError(true)
        }

        if (unitType === "" ) {
            setUnitTypeError(true)
        }

        if (frequency === "" ) {
            setFrequencyError(true)
        }

        if (name && amt && unitType && frequency) {
            
            //Change the format of Price field before saving
            const amount = parseFloat(amt.replace(/,/g, ''));
            const unit_type = unitType;
            // const type = model;


            // console.log("unitType",unitType)

            const rate = { client, property, name, type, unit_type, frequency, amount, memo, user };
            // console.log(rate)

            fetchData({
                url: '/rates/',
                method: 'post',
                data: rate
              })
        } 
    }

    return (
        <FormDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}>

            <section className="dashboard-form">

                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Rate" dashboardPreTitle="Property Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)}
                        }
                        label="Name" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <FormControl 
                        variant="outlined" 
                        fullWidth className="custom-input" 
                        error={unitTypeError}
                        required
                    >
                        <InputLabel>Unit Type</InputLabel>
                        <Select
                            className="custom-select"
                            label="Unit Type"
                            onChange={(e) => {
                                setUnitTypeError(false)
                                setUnitType(e.target.value)}
                            }
                            defaultValue = ""
                        >
                            {unittypes && unittypes.map((unittype) => { 
                                return(
                                <MenuItem key={unittype.id} value={unittype.id}>{unittype.name}</MenuItem>
                                )
                            })}   
                            
                        </Select>
                    </FormControl>

                    <FormControl 
                        variant="outlined" 
                        fullWidth className="custom-input" 
                        error={typeError}
                        required
                    >
                        <InputLabel>Pricing Model</InputLabel>
                        <Select
                            className="custom-select"
                            label="Pricing Model"
                            onChange={(e) => {
                                setTypeError(false)
                                setType(e.target.value)}
                            }
                            defaultValue = ""
                        >
                            {pricingmodels.map((pricingmodel) => { 
                                return(
                                <MenuItem key={pricingmodel.code} value={pricingmodel.code}>{pricingmodel.name}</MenuItem>
                                )
                            })}   
                            
                        </Select>
                    </FormControl>
                    
                    
                    <NumberFormat 
                        // onChange={(e) => setAmt(e.target.value)} 
                        onChange={(e) => {
                            setAmtError(false)
                            setAmt(e.target.value)}
                        }
                        className="custom-input"
                        fullWidth
                        label="Amount" 
                        variant="outlined" 
                        customInput={TextField}
                        // format="254 (###) ######" mask="_"
                        thousandSeparator={true} 
                        // prefix={'KES '} 
                        required
                        error={amtError}

                    />

                    <FormControl 
                        variant="outlined" 
                        fullWidth className="custom-input" 
                        error={frequencyError}
                        required
                    >
                        <InputLabel>Frequency</InputLabel>
                        <Select
                            className="custom-select"
                            label="Account"
                            onChange={(e) => {
                                setFrequencyError(false)
                                setFrequency(e.target.value)}
                            }
                            defaultValue = ""
                        >
                            {frequencies.map((frequency) => { 
                                return(
                                <MenuItem key={frequency.code} value={frequency.code}>{frequency.name}</MenuItem>
                                )
                            })}   
                            
                        </Select>
                    </FormControl>

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                        // required
                        // error={messageError}
                    />

                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary" type="submit">
                            Save
                        </button>
                    </div>

                </form>
            
            
            </section>
        </FormDrawer>

    ) 


}

export default RateNew;
