import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";


import useAxios from "./useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import Pagination from "./Pagination";


import { Card, CardContent } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'



// const Chart = ({ accounts, loading }) => {
const Accounts = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    //Axios
    // const { data: accounts, loading } = useAxios('/chart')
    // const {response, error } = useAxiosInterceptor();
     const { data: accounts, loading } = useAxios({
        url: '/accounts',
        method: 'get'
    })

     //Pagination
    // const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
  
     // Change page
     const paginate = pageNumber => setCurrentPage(pageNumber);
  
    return (
        // <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">

                <>
                    
                    <DashboardHeader dashboardTitle="Chart of Accounts" dashboardPreTitle="Accounting" />

                    {isMobile? 
                    <>
                    
                    {accounts && accounts.map(account => ( 

                    <Card className="dashboard-card" key={account.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{account.name}</div>
                            <div className="dashboard-card-subtitle">{account.type_name}</div>                            
                            <p>Balance: {Number(account.balance).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</p>

                            {/* <p>Account: {txn.account}</p>
                            <p>Date: {txn.date}</p>  */}
                        </CardContent>
                    </Card>
                    ))}

                    </>

                    :
                    <table className="styled-table">

                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Account Name</th>
                                <th>Type</th>
                                <th>Balance</th>
                                <th></th>
                            </tr>
                        </thead>

                        {accounts && accounts.map(account => (

                            <tbody key={account.id}>
                                <tr>
                                    <td>{account.id}</td>
                                    <td>{account.name}</td>
                                    <td>{account.type_name}</td>
                                    <td>{Number(account.balance).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td>

                                    <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td>

                                    {/* <td>icon={faEllipsisVertical}</td> */}
                                    {/* icon={faCoffee} */}
                                    {/* <td><FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /></td> */}
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    }
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={accounts.length}
                        paginate={paginate}
                    />

                    <Link to="/dashboard/accountnew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                    


                </>
                </section>

            </div>


        // </Layout>

    );

  };
  
  export default Accounts;