import React from "react";
// import axios from "axios";
import useAxios from "./useAxios";
import useAxiosInterceptor from '../components/useAxiosInterceptor';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

// import Sidebar from "./Sidebar";
import UserAlerts from "./UserAlerts";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import FormDrawer from "./FormDrawer";


function AgentNew({isDrawerOpen, handleDrawerClose}) {
    const {error, response, fetchData}  = useAxios();
    // const { error, response } = useAxiosInterceptor();

    // useEffect(() => {
    //     if (response.status === 201){
    //         navigate('/customers/');
    //     }    
    // }, [ response.status]); 


    const navigate = useNavigate();

    // const [dt, setDt] = useState(new Date());
    const [dt, setDt] = useState(null);


    const [name, setName] = useState('');
    // const [institution, setInstitution] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [type, setType] = useState('');
    const [tag, setTag] = useState('AGENT');

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');


    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)

    // const idLength = id_number.length
    // console.log(id_number.length)

    // const handleIdNumber = (e) => {
    //     if (e.target.value.length>9){
    //         setIdNumberError(true)
    //     }
    //     else{
    //         setIdNumberError(false)
    //         setId_number(e.target.value)
    //     }
    // }


    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(name, institution, email)

        if (name === '') {
            setNameError(true)
        }

        if (mobile === '' ) {
            setMobileError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        
        // console.log("ID ERROR",idNumberError)

        if (name && mobile && email && idNumberError === false ) {
            const telephone = mobile.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            const birth_date = moment(dt).format("YYYY-MM-DD")

            const agent = { client, user, name, address, email, telephone, type, tag };
            // console.log(customer)

            fetchData({
                url: '/contacts/',
                method: 'post',
                data: agent
            })
            .then(()=>
            {
                console.log(response)
                if (response.status === 200){
                    handleDrawerClose()
                    navigate('/agents/')
                }
            })
        } 
    
    }

    return(

    // <div className="dashboard-container">
    // <Layout>

    //     <div className="dashboard-main">

        <FormDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}>


            <section className="dashboard-form">
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Agent" dashboardPreTitle="Channels" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultChecked=""
                            onChange={(e) => setType(e.target.value)}
                            className="custom-input"
                        >
                            <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Individual" />
                            <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Company" />
                        </RadioGroup>

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                            }}
                            label="Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setEmailError(false)
                                setEmail(e.target.value)
                            }}
                            label="Email" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={emailError}
                        />

                        {/* <TextField className="custom-input"
                            onChange={(e) => {
                                setMobileError(false)
                                setMobile(e.target.value)
                            }}
                            label="Mobile" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={mobileError}
                        /> */}

                        <NumberFormat 
                            onChange={(e) => {
                                setMobileError(false)
                                setMobile(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Mobile" 
                            variant="outlined" 
                            customInput={TextField}
                            format="### (###) ######" mask="_"
                            required
                            error={mobileError}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => setAddress(e.target.value)}
                            label="Address" 
                            variant="outlined" 
                            fullWidth
                            // required
                            // error={mobileError}
                        />
                    

                        {/* <TextField className="custom-input"
                            onChange={handleIdNumber}
                            label="ID/Passport Number" 
                            helperText="Maximum number of characters is 9" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={idNumberError}
                        /> */}

                        {/* <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultChecked=""
                            onChange={(e) => setGender(e.target.value)}
                            className="custom-input"
                        >
                            <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                            <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                        </RadioGroup> */}

                        {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="Date of Birth"
                                value={dt}
                                onChange={(newDate) => {
                                    setDt(moment(newDate).format("YYYY-MM-DD"));
                                  }}
                                renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                            />
                        </LocalizationProvider> */}

                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary form-buttons" type="submit">
                                Save
                            </button>
                        </div>


                </form>

            </section>

        {/* </div>

        </Layout> */}
        </FormDrawer>

    )

}

export default AgentNew;
