import React, { useEffect } from "react"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// import axios from "axios";

import useAxios from "./useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import Pagination from './Pagination';

// import Fab from '@mui/material/Fab';
// import AddIcon from '@mui/icons-material/Add';
// import { Card, CardContent } from "@mui/material";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'

// import NumberFormat from "react-number-format";

//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Bookings from "./Bookings";
import BookingList from "./BookingList";
import TransactionList from "./TransactionList";
import TransactionNew from "./TransactionNew";
import authAxios from "./authAxios";


// import Sonnet from '../../components/Sonnet';

// const Transactions = ({ txns, loading }) => {
const Customer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const params = useParams()
    const navigate = useNavigate()

    const handleDrawerOpen = () => {
        // console.log("DRAWER CLOSE")
        setIsDrawerOpen(true)
    };

    const handleDrawerClose = () => {
        console.log("DRAWER CLOSE")
        setIsDrawerOpen(false)
    };

    //Axios
    // const { data: txns, loading } = useAxios('/statement')
    // const { data: txns, loading } = useAxios(`/statement/?customer=${params.id}`)
    // const {response, error } = useAxiosInterceptor();


    // FETCH CLIENT PROFILE
    const clientID = localStorage.getItem('clientID');
    const clientProfileID = localStorage.getItem('clientProfile');

    const { data: clientprofile } = useAxios({
        url: `/clientprofiles/${clientProfileID}`,
        method: 'get'
    })  


    // FETCH CUSTOMER
    const { data: customer } = useAxios({
        url: `/customers/${params.id}`,
        method: 'get'
    })  

    // FETCH TRANSACTIONS  
    // const { data: txns } = useAxios({
    //     url: "/transactions",
    //     method: 'get'
    // })
    // const transactions = txns.filter(function(txn) {
    //     return txn.customer === parseInt(customer.id);
    // });

    // const customer = params.id

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const [transactions, setTransactions] = useState([]);
    async function fetchTransactions () {
    
        try {
            // const response = await axios.get("/transactions/");
            const response = await authAxios.get(`/statement/?customer=${params.id}&page=${page}`);
            setPageCount(response.data.page_count)
            setTransactions(Array.from(response.data.results))

            console.log("RESPONSE",response.data)
            console.log("TRANSACTIONS", transactions)

        } catch (error) {
            // console.error(error);
            console.log(error);

        }
    }

    // console.log("TAB", tab)

    
    //  Fetch Bookings 
    const { data: bookings } = useAxios({
        url: "/bookings",
        method: 'get'
    })
    const customerBookings = bookings.filter(function(booking) {
        return booking.customer === parseInt(customer.id);
    });

    //FETCH TRANSACTION TYPES 
    const { data: ttypes } = useAxios({
        url: '/transtypes',
        method: 'get'
    })
    
    
    const invoice = ttypes.find(function(ttype) {
        return (ttype.code === "10");
    });


    const receipt = ttypes.find(function(ttype) {
        return (ttype.code === "20");
    });

    // console.log("TTYPES",ttypes, "INVO", invoice, "REC", receipt)

    //  Fetch Transactions 
    // const { data: transactions } = useAxios({
    //     url: "/transactions",
    //     method: 'get'
    // })
    // const customerTransactions = transactions.filter(function(transaction) {
    //     return transaction.customer === parseInt(customer.id);
    // });

    // const [customerTransactions, setcustomerTransactions] = useState(txns);

    // useEffect(() => {
    //     // setcustomerTransactions(txns)
    // }, [transactions]); 


    const handleFabClick = () => {
        navigate('/ratenew/',{state:{property:params.id}})
    }
    

    // const [sumInvoices, setSumInvoices] = useState(0)
    let sumInvoices = 0
    let sumReceipts = 0
    let balance = 0
    let cntBookings = 0
    let sumBookings = 0
    // let balance = 0
    // let customer = ""
        
    const calcStats = () => {
        // const [sumInvoices, setSumInvoices] = useState(0)
        try {

            for(let i= 0; i < transactions.length; i++){
                if (transactions[i].type===invoice.id){
                    sumInvoices = sumInvoices +  parseFloat(transactions[i].amount)
                }
                if (transactions[i].type===receipt.id){
                    sumReceipts = sumReceipts +  parseFloat(transactions[i].amount)
                }
                // customer = customerTransactions[i].customer_name

                // setSumInvoices(invoice+customerTransactions[i].amount)
                // console.log("INV",customerTransactions[i].amount)
            }
            balance = sumInvoices - sumReceipts

            //Calculate Booking Stats
            for(let i= 0; i < customerBookings.length; i++){
                cntBookings = cntBookings + 1
                sumBookings = sumBookings + parseFloat(customerBookings[i].price)
            }
        } catch (error) {
            // console.error(error);
            console.log(error);

        }

    }
    calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    // const [activeKey, setActiveKey] = useState('profile');

    const [removeBorder, setRemoveBorder] = useState(true);

    const activeTabStatement = () => {
        setKey('statement')
    }

    // console.log("KEY", key)

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
        console.log("PAGE", page)
        // fetchTransactions()
    };

    // const [loading, setLoading] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(5);
  
    // Change page
    //  const paginate = pageNumber => setCurrentPage(pageNumber);


    useEffect(()=>{
        fetchTransactions()
    },[isDrawerOpen, key, page])


    return (
        // <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle={customer.name} dashboardPreTitle="Customers" removeBorder={removeBorder} />

                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Address
                                </div>
                                <div>
                                    {customer.address}
                                </div>
                            </div>
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Number of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        {cntBookings}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Occupancy
                                    </div>
                                    <div className="dashboard-card-text">
                                        0%
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        
                        <Tab eventKey="bookings" title="Bookings">
                            {/* <BookingList  bookings={customerBookings} property={unit.property}/> */}
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Number of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        {cntBookings}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Value of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {sumBookings.toLocaleString()}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Average Length of Stay (Days)
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* {sumReceipts} */}
                                        0
                                    </div>
                                </div>

                            </div>
                            <BookingList  bookings={customerBookings} />

                        </Tab>
                        <Tab eventKey="statement" title="Statement">
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Invoices
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {sumInvoices.toLocaleString()}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Receipts
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {sumReceipts.toLocaleString()}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Balance
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {balance.toLocaleString()}
                                    </div>
                                </div>

                            </div>
                            {/* <TransactionList module="CRM" transactions={transactions} fetchTransactions={fetchTransactions}/> */}
                            <TransactionList 
                                module="CRM" 
                                transactions={transactions} 
                                fetchTransactions={fetchTransactions} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            />

                            {/* <TransactionNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} activeTabStatement={activeTabStatement}/> */}
                        </Tab>
                    </Tabs>     

                </section>

            </div>

        // {/* </Layout> */}

    );
  };
  
  export default Customer;