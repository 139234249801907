import React from "react"

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];
  
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav>
        <ul className='pagination custom-pagination'>
          {/* <li className="page-item"> 
            <a onClick={() => paginate(prevnumber)} href='#' className='page-link'>
              Previous
            </a>
          </li> */}
          
          <li className="page-item custom-pagination-prev"><a className="page-link" href="#">Prev</a></li>

          <div className="custom-pagination-pageno">
            {pageNumbers.map(number => (
              <li key={number} className='page-item'>
                <a onClick={() => paginate(number)} href='!#' className='page-link'>
                  {number}
                </a>
              </li>
            ))}
          </div>

          <li className="page-item custom-pagination-next"><a className="page-link" href="#">Next</a></li>
        </ul>
      </nav>
    );
  };
  
  export default Pagination;