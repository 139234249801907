import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import useAxios from "./useAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
// import Pagination from './Pagination';
import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent, TablePagination } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'
import FormDrawer from "./FormDrawer";
import TransactionNew from "./TransactionNew";
import CustomPagination from "./CustomPagination";
import AgentNew from "./AgentNew";


// const Transactions = ({ transactions, loading }) => {
const AgentList = ({module, agents, fetchAgents, handlePageChange, page, pagecount}) => {

    // console.log(activeTab)
    // if (loading) {
    //   return <h2>Loading...</h2>;
    // }
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

 
    useEffect(()=>{
        fetchAgents()
        // console.log("WE ARE HERE")
    },[isDrawerOpen])


    return (
        <>

        {isMobile? 
            <>
            
            {agents && agents.map(agent => ( 

            <Card className="dashboard-card" key={agent.id}>
                
                <CardContent className="dashboard-card-content">
                    <div className="dashboard-card-title">{agent.amount}</div>
                    <div className="dashboard-card-subtitle">{agent.trans_type}</div>                            
                    <p>Reference: {agent.reference}</p>
                    <p>Account: {agent.account}</p>
                    <p>Date: {agent.date}</p>
                    {/* <Pillchip label={booking.status_name} /> */}
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Booking</Button>
                </CardActions> */}
            </Card>
            ))}

            </>

            :
            // If Device is not mobile, display data using a table
            <>
            {agents && 

            <table className="styled-table">
                <thead>
                    <tr >
                        <th>ID</th>
                        <th>Name</th>
                        <th>Telephone</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Balance</th>
                        <th></th>
                    </tr>
                </thead>

                {/* {agents && agents.map(agent => ( */}

                {agents.map(agent => (
                    <tbody key={agent.id}>
                        <tr>
                            <td>{agent.id}</td>
                            <td>{agent.name}</td>
                            <td>{agent.telephone}</td>
                            <td>{agent.email}</td>
                            <td>{agent.address}</td>
                            <td>{Number(agent.balance).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td>
                            {/* <td>{agent.entry_type}</td> */}
                            <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td>

                        </tr>
                    </tbody>
                ))
                }

                
            </table>
            }

            </>

        }
            
        <AgentNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} module={module}/>
        <Pagination 
            className="custom-mui-pagination"
            // color="primary" 
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        />

        <Fab 
            className="fab" 
            color="primary" 
            aria-label="add"
            onClick={()=>{
                handleDrawerOpen()
            }
            }
        >
            <AddIcon />
        </Fab> 

        </>

    );
  };
  
  export default AgentList;