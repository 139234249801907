import React from "react"
import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

// import axios from "axios";
import useAxios from "./useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import AgentList from "./AgentList";

// import { useMediaQuery } from 'react-responsive'
// import Pagination from './Pagination';
// import Fab from '@mui/material/Fab';
// import AddIcon from '@mui/icons-material/Add';
// import { Card, CardContent } from "@mui/material";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
// import FormDrawer from "./FormDrawer";


const Agents = () => {
    //Axios
    const {error, response, data, fetchData}  = useAxios();   

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();


    // FETCH AGENTS
    const [agents, setAgents] = useState([]);

    async function fetchAgents () {
        try {
            fetchData({
                url: `/agents/?page=${page}`,
                method: 'get'
            })
            .then(()=>{
                // console.log("DATA",data)
                // console.log("RESPONSE",response)
                setAgents(data.results);
                setPageCount(data.page_count)
            })

        } catch (error) {
            console.error(error);
        }
    }
    // fetchAgents;

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
        console.log("PAGE", page)
        // fetchTransactions(page)
    };


    // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    // const [module, setModule] = useState("ACC")


    // const handleDrawerOpen = () => {
    //     // console.log("DRAWER CLOSE")
    //     setIsDrawerOpen(true)
    // };

    // const handleDrawerClose = () => {
    //     console.log("DRAWER CLOSE")
    //     setIsDrawerOpen(false)
    // };

    useEffect(()=>{
        fetchAgents()
        
    },[page, data.count])


    return (
        <Layout>

            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle="Agents" dashboardPreTitle="Channels" />
                    {agents &&
                    <AgentList 
                        module="CMS" 
                        agents={agents} 
                        fetchAgents={fetchAgents} 
                        handlePageChange={handlePageChange} 
                        page={page} 
                        pagecount={pagecount}
                    />
                    }
                    

                </section>

            </div>


        </Layout> 

    );
  };
  
  export default Agents;