import React from "react"

import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
// import Main from "../components/Main";
import Features from "../components/Features";
import Cta from "../components/Cta";


function Home() {
    return (
        <>
            <div className="container-fluid">
                <div className="hero-bg-lightblue">
                    <div className="container">
                        <Header />
                        <Hero /> 
                        <Features />
                        <Cta />
                        <Footer />        
                    </div>
                </div>
               
            </div>

            {/* <div className="container-lower">
                <Main />
                <Footer />
            </div> */}
        </>
    ) 
}

export default Home