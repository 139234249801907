import React from "react";
import { Chip } from "@mui/material";

const Pillchip = ({label}) => {
    let color = '';
    const code = label;

    switch (code) {
        case "Vacant":
            color = "info"
            break;
        case "Reserved":
            color = "primary"
            break;
        case "Occupied":
            color = "success"
            break;
        case 4:
            color = "warning"
            break;
        default:
            color = "default";
    }

    // console.log(label, color)
    return (
        <Chip label={label} size="small" color={color} className="custom-pill-chip"/>

      );
}
 
export default Pillchip;