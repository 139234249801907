import React from "react";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

import useAxios from "./useAxios";
import authAxios from "./authAxios";

// import useAxiosInterceptor from "./useAxiosInterceptor";


import Pagination from "./Pagination";

// import Fab from '@mui/material/Fab';
import { Card, CardContent } from "@mui/material";
import { useMediaQuery } from 'react-responsive'
import DashboardHeader from "./DashboardHeader";
import Layout from "./Layout";
import ThreeDotsMenu from "./ThreeDotsMenu";

function Units() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [units, setUnits] = useState([]);
    const { data, fetchData}  = useAxios();   


    //Axios
    //  const { data: units, loading } = useAxios({
    //     url: '/units',
    //     method: 'get'
    // })

    
    async function fetchUnits () {
        try {
            const response = await authAxios.get(`/units/?page=${page}`);
            setUnits(Array.from(response.data.results))
            // setPageCount(response.data.page_count)


            // fetchData({
            //     url: `/units/?page=${page}`,
            //     method: 'get'
            // })
            // setUnits(Array.from(data.results))
            // setPageCount(data.page_count)

        } catch (error) {
            console.error(error);
        }
    }


    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);

     // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    // const currentPosts = units.slice(indexOfFirstPost, indexOfLastPost);
 
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/unit/",
        }, 
        // {
        //     id: 2,
        //     menu: "Booking",
        //     url: "/bookingnew/",
        // }
    ];

    useEffect(()=>{
        fetchUnits()
    },[page])

    return (
        // <Layout>

        <div className="dashboard-main">

            <div className="dashboard-content">

                <DashboardHeader dashboardTitle="Units" dashboardPreTitle="Property Management" />

                {isMobile? 
                <>
                
                {units && units.map(unit => ( 

                <Card className="dashboard-card" key={unit.id}>
                    {/* <CardHeader
                        className="dashboard-card-header"
                        title={unit.reference} 
                        subheader={unit.property_name}
                    /> */}

                    <CardContent className="dashboard-card-content">
                        <div className="dashboard-card-title">{unit.reference}</div>
                        <div className="dashboard-card-subtitle">{unit.property_name}</div>                            
                        {/* <p>Price: {unit.price}</p> */}
                        <p>{unit.unit_type}</p>
                        <p>Capacity: {unit.capacity}</p>

                        {/* <Typography>Price: {unit.price}</Typography> */}
                        {/* <p>{unit.price}</p>      */}
                        {/* <Pillchip label={unit.room_status} /> */}


                    </CardContent>
                    {/* <CardActions>
                        <Button size="small">Booking</Button>
                    </CardActions> */}
                </Card>
                ))}

                </>

                :
                // If Device is not mobile, display data using a table
                // {units && units.map(unit => ( 

                <table className="styled-table">
                    <thead>
                        <tr >
                            <th>Reference</th>
                            <th>Property</th>
                            <th>Type</th>
                            {/* <th>Price</th> */}
                            <th>Capacity</th>
                            <th>Status</th>

                            <th></th>
                        </tr>
                    </thead>

                    {units && units.map(unit => (
                        <tbody key={unit.id}>
                            <tr >   
                                <td data-label="Reference">{unit.reference}</td>

                                <td data-label="Property">{unit.property_name}</td>
                                <td data-label="Type">{unit.unit_type}</td>
                                {/* <td data-label="Price">{Number(unit.price).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td> */}
                                <td data-label="Capacity">{unit.capacity}</td>
                                <td data-label="Status">{unit.room_status}</td>


                                {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /> */}
                                <td className='styled-table-menu'>
                                    {/* <ThreeDotsMenu data={unit} /> */}
                                    <ThreeDotsMenu data={unit} threeSubMenu={threeSubMenu} />
                                </td>

                            </tr>
                            {/* </Link> */}

                        </tbody>

                    ))}
                    
                </table>
                }
                
                <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={units.length}
                    paginate={paginate}
                />

                {/* <Link to="/unitnew" className="nav-link">
                    <Fab className="fab" color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </Link> */}
            </div>
        </div>
        // </Layout>


    )
}
export default Units