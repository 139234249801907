import React from "react"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import useAxios from "./useAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import Pagination from './Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'
import FormDrawer from "./FormDrawer";
// import TransactionNew from "./TransactionNew";
import TransactionList from "./TransactionList";
import authAxios from "./authAxios";


// const Transactions = ({ txns, loading }) => {
const Transactions = () => {
    // if (loading) {
    //   return <h2>Loading...</h2>;
    // }
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })


    //Axios
    const { data: txns, loading } = useAxios('/transactions')

    const { data, fetchData}  = useAxios();   


    // const {response, error } = useAxiosInterceptor();

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();


    const [transactions, setTransactions] = useState([]);

    async function fetchTransactions () {
        try {
            const response = await authAxios.get(`/transactions/?page=${page}`);
            setTransactions(Array.from(response.data.results))
            setPageCount(response.data.page_count)
            // fetchData({
            //     url: `/transactions/?page=${page}`,
            //     method: 'get'
            // })
            // setTransactions(Array.from(data.results))
            // setPageCount(data.page_count)


        } catch (error) {
            console.error(error);
        }
    }


    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
        // console.log("PAGE", page)
        // fetchTransactions(page)
    };

    // const [loading, setLoading] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(5);
  
    // Change page
    // const paginate = pageNumber => setCurrentPage(pageNumber);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [module, setModule] = useState("ACC")


    const handleDrawerOpen = () => {
        // console.log("DRAWER CLOSE")
        setIsDrawerOpen(true)
    };

    const handleDrawerClose = () => {
        console.log("DRAWER CLOSE")
        setIsDrawerOpen(false)
    };

    useEffect(()=>{
        fetchTransactions()
        // console.log("TAB", key)
        // console.log("DRAWER", isDrawerOpen)
    },[page])

    return (
        // <Layout>

            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle="Transactions" dashboardPreTitle="Accounting" />

                    <TransactionList 
                        module="ACC" 
                        transactions={transactions} 
                        fetchTransactions={fetchTransactions} 
                        handlePageChange={handlePageChange} 
                        page={page} 
                        pagecount={pagecount}
                    />

                </section>

            </div>


        // </Layout> 

    );
  };
  
  export default Transactions;