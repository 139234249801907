import React from "react"
import axios from 'axios';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import Sidebar from "./Sidebar"
import Pagination from './Pagination';
// import Transactions from './Transactions';
// import Chart from "./Chart";
import Accounts from "./Accounts";


function Dashchart() {
    //const [leads, setLeads] = useState(null)
    const [accounts, setAccounts] = useState([])

    //Pagination
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);


    useEffect(() => {

        // axios.get('http://127.0.0.1:8000/api/chart/')
        axios.get('/chart/')
        .then(function (response) {
        // handle success
        setAccounts(response.data);
        setLoading(false);
        //console.log("leads:", leads)
        console.log("response:",response.data);
        })
        .catch(function (error) {
        // handle error
        console.log("ERROR", error);
        })
        .then(function () {
        // always executed

        });

    }, [])


    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = accounts.slice(indexOfFirstPost, indexOfLastPost);

    //console.log(leads, indexOfLastPost, indexOfFirstPost, currentPosts)

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    //console.log(currentPage)

    return (
        <div className="dashboard-container">
            <Sidebar />

            {/* <Leads  leads={leads} loading={loading}/> */}
            <div className="dashboard-main">

                <section className="dashboard-content">

                    <Accounts accounts={currentPosts} loading={loading}/>

                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={accounts.length}
                        paginate={paginate}
                    />

                    <Link to="/chartnew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>

                </section>
            </div>

        </div>


    ) 
}

export default Dashchart