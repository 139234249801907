import React from "react"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import useAxios from "./useAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import Pagination from './Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'

// import NumberFormat from "react-number-format";

//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Bookings from "./Bookings";
import BookingList from "./BookingList";

// import Sonnet from '../../components/Sonnet';

// const Transactions = ({ txns, loading }) => {
const Unit = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    const params = useParams()
    const navigate = useNavigate()

    const handleFabClick = () => {
        navigate('/ratenew/',{state:{property:params.id}})
      }

    //  Fetch Unit
    const { data: unit } = useAxios({
        url: `/units/${params.id}`,
        method: 'get'
    })
    const unitName = unit.reference+" - "+unit.property_name

    //  Fetch Property Types
    const { data: rates } = useAxios({
        url: `/ratesbyproperty/?property=${params.id}`,
        method: 'get'
    })

    //  Fetch Bookings 
     const { data: bookings } = useAxios({
        url: `/unitbookings/?unit=${params.id}`,
        method: 'get'
    })


    //Axios
    // const { data: txns, loading } = useAxios('/statement')
    // const { data: txns, loading } = useAxios(`/statement/?customer=${params.id}`)
    // const {response, error } = useAxiosInterceptor();

    // const [sumInvoices, setSumInvoices] = useState(0)
    let sumBookings = 0
    let cntBookings = 0
    // let customer = ""
        
    const calcStats = () => {

        //Calculate Booking Stats
        for(let i= 0; i < bookings.length; i++){
            cntBookings = cntBookings + 1
            sumBookings = sumBookings + parseFloat(bookings[i].price)
        }

    }
    calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const [removeBorder, setRemoveBorder] = useState(true);


     //Pagination
    // const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
  
     // Change page
     const paginate = pageNumber => setCurrentPage(pageNumber);

    // useEffect(() => {
    //     console.log("WE ARE HERE")

    // },[key])

    return (
        <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle={unitName} dashboardPreTitle="Property Unit" removeBorder={removeBorder} />

                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            
                            {/* <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Address
                                </div>
                                <div>
                                    {property.address}
                                </div>
                            </div> */}
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Number of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        {cntBookings}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Value of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {sumBookings.toLocaleString()}
                                    </div>
                                </div>
                                {/* <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Occupancy
                                    </div>
                                    <div className="dashboard-card-text">
                                        0%
                                    </div>
                                </div> */}
                            </div>
                        </Tab>
                        
                        <Tab eventKey="bookings" title="Bookings">
                            <BookingList  bookings={bookings} property={unit.property} module="PM"/>
                        </Tab>
                        <Tab eventKey="inventory" title="Inventory" disabled>
                            inventory
                        </Tab>
                    </Tabs>     

                </section>

            </div>

        </Layout>

    );
  };
  
  export default Unit;