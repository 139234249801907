import React from "react";
import { useNavigate } from "react-router-dom";


function Cta() {
    const navigate = useNavigate();

    return (
        <section className="cta">

            <div className="cta-text">
                Whether you own or manage a single or multi-unit property, we provide customised technology solutions for accommodation management. Contact us today to book a demo.
            </div>
            <div className="cta-button">
              <button className="btn btn-outline-primary" onClick={() => navigate("/contact")}>Request Demo</button>
            </div>
        </section>

    ) 
}
    
export default Cta