import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './style.css';
import App from './App';

import { createRoot } from 'react-dom/client';

// set axios global defaults
import axios from 'axios';
// import axios from './components/useAxios';
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

//React 17 Deprecated
// ReactDOM.render(
//   <React.StrictMode>
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

//React 18 Changes
// const root = ReactDOM.createRoot(document.getElementById("root"));
const root = createRoot(document.getElementById("root"));


root.render(
  // <React.StrictMode> //****DISABLED BECAUSE WAS DUPLICATING API CALLS IN AXIOS
  // <React.StrictMode> 

    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);



// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<App tab="home" />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
