import React from "react"
// import axios from 'axios';
import useAxios from "./useAxios";

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";


import Sidebar from "./Sidebar";

import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";


function AccountNew() {

    //Axios Post Custom Hook
    const {error, response, fetchData}  = useAxios();
    // const [acctypes, setAcctypes] = useState([])

    //  Fetch Account Types
    const { data: acctypes } = useAxios({
        url: '/accounttypes',
        method: 'get'
    })

    //Redirect back to Dashboard after successful post
    useEffect(() => {
        if (response.status === 201){
            navigate('/accounts/');
        }    
    }, [ response.status]); 
    

    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    // const [user, setUser] = useState('1');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');


    const [typeError, setTypeError] = useState(false)
    const [nameError, setNameError] = useState(false)


    const navigate = useNavigate();

    const handleSubmit = (e) => {
     
        e.preventDefault();

        setNameError(false)
        setTypeError(false)

        if (name === '') {
            setNameError(true)
        }

        if (type === '' ) {
            setTypeError(true)
        }


        if (name && type ) {
            const account = { client, type, name, description, user };
            console.log(account)

            fetchData({
                url: '/accounts/',
                method: 'post',
                data: account
              })
        } 
    }


    return (
        
        // <Layout>

            <div className="dashboard-main">
                {/* <section className="dashboard-content"> */}

                <section className="dashboard-form">
                    <DashboardHeader dashboardTitle="Add Account" dashboardPreTitle="Accounting" />

                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            error={typeError}
                            required
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                className="custom-select"
                                label="Type"
                                onChange={(e) => {
                                    setTypeError(false)
                                    setType(e.target.value)}
                                }
                                defaultValue = ""
                            >
                                {acctypes.map((acctype) => { 
                                    return(
                                    <MenuItem key={acctype.code} value={acctype.id}>{acctype.name}</MenuItem>
                                    )
                                })}   
                               
                            </Select>
                        </FormControl>

                        {/* <div className="form-group">
                            <label className="form-label">
                                Account Type
                            </label>

                            <select 
                                name="account_type" 
                                id="account_type" 
                                className="form-select"
                                onChange={(e) => setAccount_type(e.target.value)}
                                >

                                <option value="">----Select----</option>
                                {acctypes.map((e, key) => {
                                    return  <option key={e.id} value={e.id}>{e.name}</option>;

                                })}                            
                            </select>
                        </div> */}

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)}
                            }
                            label="Name" 
                            variant="outlined" 
                            // color="secondary" 
                            fullWidth
                            required
                            error={nameError}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => setDescription(e.target.value)}
                            label="Description"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                            required
                            // error={messageError}
                        />

                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary" type="submit">
                                Save
                            </button>
                        </div>

                    </form>
                
                
                </section>
            </div>
        // </Layout>

    ) 


}

export default AccountNew;
