import React from "react";
import { useState } from "react";

// import axios from "axios";
// import useAxios from "./useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

// import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

// import Sidebar from "./Sidebar"
// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';

import Pillchip from "./Pillchip";
// import DashboardHeader from "./DashboardHeader";

import { Card, CardContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { useMediaQuery } from 'react-responsive'
import ThreeDotsMenu from "./ThreeDotsMenu";
import BookingNew from "./BookingNew";
// import Layout from "./Layout";

const BookingList =({bookings, property, module, handlePageChange, page, pagecount, isCheckIn}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const navigate = useNavigate()
    const params = useParams()

    // console.log("BOOKINGS",bookings)

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    // const [isCheckOut, setIsCheckOut] = useState(true)


    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    // const threeSubMenu = [
    const checkInSubMenu = [
    
        {
            id: 1,
            menu: "Check-in",
            url: "/checkin/",
        },
        
        // {
        //      id: 2,
        //      menu: "No Show",
        //     //  url: "/bookingnew/",
        // }
    ];

    const checkOutSubMenu = [
        {
            id: 2,
            menu: "Check-out",
            url: "/checkout/",
        }
    ];

    //Pagination
    // const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);

     // Get current posts
     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
 
     // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>

            {isMobile? 
                <>
                
                {bookings && bookings.map(booking => ( 

                <Card className="dashboard-card" key={booking.id}>
                    
                    <CardContent className="dashboard-card-content">
                        <div className="dashboard-card-title">{booking.reference}</div>
                        <div className="dashboard-card-subtitle">{booking.customer_name}</div>                            
                        <p>Start: {booking.start}</p>
                        <p>End: {booking.end}</p>
                        <Pillchip label={booking.status_name} />
                    </CardContent>
                    {/* <CardActions>
                        <Button size="small">Booking</Button>
                    </CardActions> */}
                </Card>
                ))}

                </>

                :
                // If Device is not mobile, display data using a table
                <>

                <table className="styled-table">
                    <thead>
                        <tr >
                            <th>Reference</th>
                            <th>Date</th>
                            <th>Unit</th>
                            <th>Customer</th>
                            <th>Status</th>
                            <th>Check In</th>
                            <th>Check Out</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>

                    {bookings && bookings.map(booking => (

                        <tbody key={booking.id}>
                            <tr>
                                <td>{booking.reference}</td>
                                <td>{booking.date}</td>
                                <td>{booking.unit_no}</td>
                                <td>{booking.customer_name}</td>

                                {/* <td>{room.room_status}</td> */}
                                <td>
                                    <Pillchip label={booking.status_name} />
                                </td>
                                <td>{booking.start}</td>
                                <td>{booking.end}</td>
                                <td>{Number(booking.price).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td>


                                {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td> */}
                                <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={booking} threeSubMenu={isCheckIn? checkOutSubMenu: checkInSubMenu} />
                                </td>
                            </tr>
                        </tbody>
                    ))}
                    

                </table>
                </>
            }

            {/* <BookingNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} /> */}

            {/* <Pagination
                postsPerPage={postsPerPage}
                totalPosts={bookings.length}
                paginate={paginate}
            /> */}
            <Pagination 
            className="custom-mui-pagination"
            // color="primary" 
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        />

            { module === "PM" ?
            <Fab 
                className="fab" 
                color="primary" 
                aria-label="add"
                onClick={()=>{
                    // navigate('/bookingnew/',{state:{unit:params.id}})
                    navigate('/bookingnew/',{state:{property:property, unit:params.id}})
                    // handleDrawerOpen()

                }}
            >

                <AddIcon />
            </Fab>
            :
            null
            }
           

        </>

    )
}
export default BookingList