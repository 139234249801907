import React from "react";

import useAxios from "./useAxios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { TextField } from "@mui/material";


import moment from 'moment';


import UserAlerts from "./UserAlerts";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomSnackBar from "./CustomSnackBar";
import authAxios from "./authAxios";

const CheckOut = ({isDrawerOpen, handleDrawerClose }) => {

    // const location = useLocation();
    const params = useParams()
    const {error, response, data, fetchData}  = useAxios();


    //Axios
    // FETCH DEFAULT VALUES
    // const [booking, setBooking] = useState(params.id);
    const booking = params.id;
    const [customer, setCustomer] = useState('');
    const [name, setName] = useState('');
    const [unit, setUnit] = useState('');
    const [property, setProperty] = useState('');
    // const [stays, setStays] = useState('');
    const [stay1, setStay1] = useState('');

   
    async function fetchDefaultValues () {
        try {
            const response = await authAxios.get(`/bookings/${params.id}`);
            setCustomer(response.data.customer)
            setUnit(response.data.unit)
            const response1 = await authAxios.get(`/customers/${response.data.customer}`);
            setCustomer(response1.data.id)
            setName(response1.data.name)
            const response2 = await authAxios.get(`/units/${response.data.unit}`);
            const response3 = await authAxios.get(`/properties/${response2.data.property}`);
            setProperty(response3.data.name+" "+response2.data.reference)

            // const response4 = await authAxios.get("/stays");
            // setStays(response4.data)
            // console.log("STAYS", stays)

            // const stay1 = stays.find(stay1 => stay1.booking === params.id)
            // setStay1(stays.find(stay => stay.booking === params.id))

            // console.log("STAY 1", stay1)
            // checkedin.push(bookings.find(booking => booking.id === parseInt(stay.booking)))
            // const debtors = accounts.find(account => account.id === clientprofile.debtors_control)

            // const stay = stays.find(stay => stay.booking === params.id)
            // console.log("STAY 1", stay)



            // setStay(response4.data.filter)

        } catch (error) {
            console.log(error);

        }
    }

    const { data: stays } = useAxios({
        url: '/stays',
        method: 'get'
    })

    console.log("STAYS", stays)

    const st = stays.find(stay => stay.booking === params.id)
    console.log("STAY 1", st)
    

    const navigate = useNavigate();
    const [dt, setDt] = useState(new Date());
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [dtError, setDtError] = useState(false)

    //Handle snackbar for success feedback to the user
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSnackBarClose = () => {
        // console.log("DRAWER CLOSE")
        setSuccess(false)
        };

    const handleSubmit = (e) => {
        e.preventDefault();

        // setCustomerError(false)

        if (dt === '' || dt === null ) {
            setDtError(true)
        }

        console.log("ERROR",dtError)

        if (dt) {
            
            // const st = stays.find(stay => stay.booking === params.id)
            // console.log("STAY 1", st)
            
            // const price = parseFloat(amount.replace(/,/g, ''));
            // console.log("DT",dt)
            // const checkin = moment(dt).format("YYYY-MM-DD")



            const checkout = moment(dt).format("YYYY-MM-DD HH:mm:ss")

            const stay = { client, booking, unit, customer, checkout, memo, user };

            // console.log("stay:", stay)
         
            fetchData({
                url: '/stays/',
                method: 'put',
                data: stay
              })
            .then(response=>{
                // console.log(response)
                setSuccess(true)
                setSuccessMessage("Guest check-in successful!")
                // navigate('/bookings/')

            })
        } 
    
    }

    useEffect(() => {
        fetchDefaultValues()
        // if (stays){
        //     setStay1(stays.find(stay => stay.booking === params.id))
        //     console.log("STAY 1", stay1)
        // }
        // console.log("USE EFFECT", stays)



        //Redirect back to Bookings after successful checkin
        if (response.status === 201 && success === false){
            navigate('/bookings/')
        }    

    }, [response.status, stays.length, stay1, success]); 

    return(

        <Layout>

        <div className="dashboard-main">


            <section className="dashboard-form">

                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Check-Out" dashboardPreTitle="Property Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <TextField className="custom-input"
                            disabled
                            value={name}
                            label="Customer" 
                            variant="outlined" 
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />

                        <TextField className="custom-input"
                            disabled
                            value={property}
                            label="Property" 
                            variant="outlined" 
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                // renderInput={(props) => <TextField {...props} />}
                                renderInput={(params) => <TextField autoFocus fullWidth required className="custom-input" {...params} error={dtError} />}

                                label="Check-Out Date/Time"
                                value={dt}
                                onChange={(newValue) => {
                                    setDtError(false)
                                    setDt(newValue);
                                }}
                            />
                        </LocalizationProvider>


                        <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Comments"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                            // required
                            // error={detailsError}
                        /> 

                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary" type="submit">
                                Save
                            </button>
                        </div>

                        <CustomSnackBar success={success} successMessage={successMessage} handleSnackBarClose={handleSnackBarClose}/>

                </form>

            </section>
        
            </div>

    </Layout>    
    )
}

export default CheckOut;