import React from "react"
import { useEffect, useState } from "react";
import useAxios from "./useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";


function Dashboard() {

    //Axios
    const { data: profile, loading } = useAxios('/profile')
    // const {response, error } = useAxiosInterceptor();

    // FETCH CLIENT PROFILE
    const clientID = localStorage.getItem('clientID');
    const clientProfileID = localStorage.getItem('clientProfile');


    const { data: clientprofile } = useAxios({
        url: `/clientprofiles/${clientProfileID}`,
        // url: "/clientprofiles",
        method: 'get'
    })  

    // console.log("PROFILE",clientprofile.id)


    // FETCH CUSTOMER
    const { data: customers } = useAxios({
        // url: `/customers/${params.id}`,
        url: "/customers",
        method: 'get'
    })  

    // FETCH LEADS
    const { data: leads } = useAxios({
        url: "/leads",
        method: 'get'
    })  

    // FETCH BOOKINGS 
    const { data: bookings } = useAxios({
        url: "/bookings",
        method: 'get'
    })


    //FETCH ACCOUNT TYPE 
    // const [accounttypes, setAccounttypes] = useState([]);
    // const { data: accttypes } = useAxios({
    const { data: accounttypes } = useAxios({
        url: '/accounttypes',
        method: 'get'
    })
    // setAccounttypes(Object.values(accttypes.results))
    // const accounttypes = Object.values(accttypes.results)

    // console.log(accounttypes)
    // setTransactions(Object.values(response.data.results))

    // const income = Object.values(accounttypes).filter(accounttype => accounttype.code === "10100")
    const income = accounttypes.find(accounttype => accounttype.code === "10100")
    const otherincome = accounttypes.find(accounttype => accounttype.code === "10200")

    // const income = Array.from(accounttypes).filter(accounttype => accounttype.code === "10100")
    // const otherincome = Array.from(accounttypes).filter(accounttype => accounttype.code === "10200")

    // const income = accounttypes.find(function(accounttype) {
    //     return (accounttype.code === "10100");
    // });
    // const otherincome = accounttypes.find(function(accounttype) {
    //     return (accounttype.code === "10200");
    // });

    // const debtors = accounttypes.find(function(accounttype) {
    //     return (accounttype.code === "30200");
    // });
   

    // FETCH ACCOUNTS 
    const { data: accounts } = useAxios({
        url: '/accounts',
        method: 'get'
    })

    // const revaccts = Array.from(accounts).filter(account => account.type === income.id || account.type === otherincome.id)
    // const debtors = Array.from(accounts).filter(account => account.id === clientprofile.debtors_control)
    const revaccts = accounts.filter(account => account.type === income.id || account.type === otherincome.id)
    const debtors = accounts.find(account => account.id === clientprofile.debtors_control)

    console.log("ACCOUNTS",accounts)
    console.log("INCOME",income)
    console.log("OTHER INCOME",otherincome)
    console.log("REV ACCTS ",revaccts)



    // const revaccts = accounts.filter(function(account) {
    //     return account.type === income.id || account.type === otherincome.id;
    // });

    // const debtors = accounts.find(function(account) {
    //     return account.id === clientprofile.debtors_control 
    // });


    // const [sumInvoices, setSumInvoices] = useState(0)
    let cntCustomers = 0
    let cntLeads = 0
    let cntBookings = 0
    let sumBookings = 0
    let sumRevenue = 0
    // let sumDebtors = 0

    // sumDebtors = debtors.balance

    // const sumDebtors = debtors.balance

    // console.log("DEBTORS",sumDebtors)

    let sumInvoices = 0
    let sumReceipts = 0
    let balance = 0
    
    // let balance = 0
    // let customer = ""
        
    const calcStats = () => {

        // CUSTOMERS
        for(let i= 0; i < customers.length; i++){
            cntCustomers = cntCustomers + 1
        }

        // LEADS
        for(let i= 0; i < leads.length; i++){
            cntLeads = cntLeads + 1
        }

        // BOOKINGS
        for(let i= 0; i < bookings.length; i++){
            cntBookings = cntBookings + 1
            sumBookings = sumBookings + parseFloat(bookings[i].price)
        }

        // REVENUE
        for(let i= 0; i < revaccts.length; i++){
            sumRevenue = sumRevenue + parseFloat(revaccts[i].balance)
        }


        // const [sumInvoices, setSumInvoices] = useState(0)
        // for(let i= 0; i < transactions.length; i++){
        //     if (transactions[i].type===invoice.id){
        //         sumInvoices = sumInvoices +  parseFloat(transactions[i].amount)
        //     }
        //     if (transactions[i].type===receipt.id){
        //         sumReceipts = sumReceipts +  parseFloat(transactions[i].amount)
        //     }
        //     // customer = customerTransactions[i].customer_name

        //     // setSumInvoices(invoice+customerTransactions[i].amount)
        //     // console.log("INV",customerTransactions[i].amount)
        // }
        // balance = sumInvoices - sumReceipts

        

    }
    calcStats()

    // useEffect(()=>{
    //     calcStats()
    //     console.log("USE EFFECT")

    // },[])

    return (
        // <Layout>

        // <div className="dashboard-container">

            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle="Insights" dashboardPreTitle="Dashboard" />

                    <div className="dashboard-content-subheader" id="dashboard-landing">
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Leads
                            </div>
                            <div className="dashboard-card-text">
                                {cntLeads}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Customers
                            </div>
                            <div className="dashboard-card-text">
                                {cntCustomers}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Bookings
                            </div>
                            <div className="dashboard-card-text">
                                {cntBookings}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Value of Bookings
                            </div>
                            <div className="dashboard-card-text">
                                KES {sumBookings.toLocaleString()}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Revenue
                            </div>
                            <div className="dashboard-card-text">
                                KES {sumRevenue.toLocaleString()}
                                
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Debtors/Outstanding Balance
                            </div>
                            <div className="dashboard-card-text">
                                KES {debtors && parseFloat(debtors.balance).toLocaleString()}
                            </div>
                        </div>

                    </div>

                    {/* <Leads leads={currentPosts} loading={loading}/> */}
                    {/* <Leads leads={leads} loading={loading}/> */}


                    {/* <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={leads.length}
                        paginate={paginate}
                    /> */}

                </section>
            </div>


        
        // {/* </div> */}
        // </Layout>

    ) 

}

export default Dashboard