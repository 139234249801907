import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import useAxios from "./useAxios";
// import axios from "axios";
import authAxios from "./authAxios";

import { Link } from "react-router-dom";

import Pagination from "./Pagination";
import Pillchip from "./Pillchip";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent, Menu, CardActions } from "@mui/material";
import { Button } from "@mui/material";


import { useMediaQuery } from 'react-responsive'
import ThreeDotsMenu from "./ThreeDotsMenu";
import { PanoramaSharp } from "@mui/icons-material";
import UnitNew from "./UnitNew";
import { useEffect } from "react";
import RateNew from "./RateNew";

const RateList = ({fetchUnitTypes, unittypes}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
   
    // const navigate = useNavigate();
    const params = useParams();

    const property = params.id


    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
    // const handleUnitsDrawerOpen = () => {
    //     setUnitsDrawerOpen(true)
    // };

    const handleDrawerClose = () => {
        console.log("DRAWER CLOSE")
        setIsDrawerOpen(false)
    };


    // FETCH PROPERTY RATES 
    // const { data: rates } = useAxios({
    //     // url: `/ratesdetail/?property=${params.id}`,
    //     url: `/ratesbyproperty/?property=${params.id}`,
    //     method: 'get'
    // })

    const [rates, setRates] = useState([]);

    async function fetchRates () {
    
        try {
            const response = await authAxios.get(`/ratesbyproperty/?property=${params.id}`);
            setRates(response.data)

            // console.log("RESPONSE",response)
            // console.log("DATA", rates)

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        // if (!isDrawerOpen) {
            fetchRates()
        // }
        
    },[isDrawerOpen])

    // console.log("DRAWER", isDrawerOpen)

   //Pagination
   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage] = useState(5);


    // Get current posts
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = rates.slice(indexOfFirstPost, indexOfLastPost);

   // Change page
   const paginate = pageNumber => setCurrentPage(pageNumber);

   const threeSubMenu = [
       {
           id: 1,
           menu: "View",
           url: "/rate/",
       }, 
       // {
       //     id: 2,
       //     menu: "Booking",
       //     url: "/bookingnew/",
       // }
   ];

    
    
   return (
        <>
            {isMobile? 
                <>
                
                {rates && rates.map(rate => ( 

                <Card className="dashboard-card" key={rate.id}>
                    
                    <CardContent className="dashboard-card-content">
                        <div className="dashboard-card-title">{rate.amount}</div>
                        <div className="dashboard-card-subtitle">{rate.trans_type}</div>                            
                        <p>Reference: {rate.reference}</p>
                        <p>Account: {rate.account}</p>
                        <p>Date: {rate.date}</p>
                    </CardContent>
                </Card>
                ))}

                </>

                :
                // If Device is not mobile, display data using a table

                <table className="styled-table">

                    <thead>
                        <tr >
                            <th>ID</th>
                            <th>Name</th>
                            <th>Unit Type</th>
                            <th>Frequency</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>

                    {rates && rates.map(rate => (

                        <tbody key={rate.id}>
                            <tr>
                                <td>{rate.id}</td>
                                <td>{rate.name}</td>
                                <td>{rate.unit_type_name}</td>
                                <td>{rate.frequency_name}</td>
                                <td>{Number(rate.amount).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td>
                                <td className='styled-table-menu'>
                                    {/* <ThreeDotsMenu data={rate} threeSubMenu={threeSubMenu} /> */}
                                </td>
                            </tr>
                        </tbody>
                    ))}
                    

                </table>
            }

            <RateNew 
                isDrawerOpen={isDrawerOpen} 
                handleDrawerClose={handleDrawerClose} 
                unittypes={unittypes} 
                fetchUnitTypes={fetchUnitTypes}
            />

            <Pagination
                postsPerPage={postsPerPage}
                totalPosts={rates.length}
                paginate={paginate}
            />

            <Fab 
                className="fab" 
                color="primary" 
                aria-label="add"
                onClick = {()=>{
                    // navigate('/unitnew', {state:{property:params.id}})
                    handleDrawerOpen()
                }}
            >
                <AddIcon />
            </Fab>
       </>


   )

}

export default RateList