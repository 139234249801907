import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
// import useAxios from "./useAxios";
import authAxios from "./authAxios";

import useAxiosInterceptor from "./useAxiosInterceptor";

import { createSearchParams, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "./DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

// import { Menu, MenuItem } from "@mui/material";
// import { IconButton } from "@mui/material";
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import Layout from "./Layout";
import ThreeDotsMenu from "./ThreeDotsMenu";
// import FormDrawer from "./FormDrawer";


function Customers() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const { response } = useAxiosInterceptor();



    // FETCH CUSTOMERS 
    // const { data: customers, loading } = useAxios({
    //     url: '/customers',
    //     method: 'get'
    // })
    const [customers, setCustomers] = useState([]);

    async function fetchCustomers () {
        try {
            const response = await authAxios.get(`/customers/?page=${page}`);
            setPageCount(response.data.page_count)
            setCustomers(Array.from(response.data.results))

        } catch (error) {
            console.error(error);
        }
    }

    //Pagination
    // // const [loading, setLoading] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(5);

    //  // Get current posts
    //  const indexOfLastPost = currentPage * postsPerPage;
    //  const indexOfFirstPost = indexOfLastPost - postsPerPage;
    //  const currentPosts = customers.slice(indexOfFirstPost, indexOfLastPost);
 
    //  // Change page
    //  const paginate = pageNumber => setCurrentPage(pageNumber);

     const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/customer/",
        }, 
        {
            id: 2,
            menu: "Edit",
            url: "/dashboard/customeredit/",
        }
    ];

    useEffect (() => {
        fetchCustomers()
    },[ page ])
    

    return (
        // <Layout>

            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Customers" dashboardPreTitle="CRM" />

                    {isMobile? 
                    <>
                    
                    {customers && customers.map(customer => ( 

                    <Card className="dashboard-card" key={customer.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{customer.name}</div>
                            <div className="dashboard-card-subtitle">{customer.telephone}</div>                            
                            <p>{customer.address}</p>
                            <p>{customer.email}</p>
                            <p>Gender: {customer.gender}</p>
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Telephone</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th></th>
                            </tr>
                        </thead>

                        {customers && customers.map(customer => (

                            <tbody key={customer.id}>
                                <tr>
                                    <td>{customer.id}</td>
                                    <td>{customer.name}</td>
                                    <td>{customer.gender}</td>
                                    <td>{customer.telephone}</td>
                                    <td>{customer.email}</td>
                                    <td>{customer.address}</td>

                                    {/* <td>
                                        <Pillchip label={room.room_status} />
                                    </td>
                                    <td>{Number(customer.price).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td> */}

                                    {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td> */}
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={customer} threeSubMenu={threeSubMenu} />

                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    {/* <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={customers.length}
                        paginate={paginate}
                    /> */}

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/customernew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                </section>
            </div>

        // </Layout>


    )
}
export default Customers