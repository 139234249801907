import React from "react"
// import axios from 'axios';
import useAxios from "./useAxios";
import authAxios from "./authAxios";


import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";


// import Sidebar from "./Sidebar";

import { Autocomplete, TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Add';


import NumberFormat from "react-number-format";

import moment from 'moment';
import UserAlerts from "./UserAlerts";
import FormDrawer from "./FormDrawer";
// import Layout from "./Layout";


const TransactionNew = ({isDrawerOpen, handleDrawerClose, module}) => {

// function TransactionNew() {
    const {error, response, fetchData}  = useAxios();
    // console.log("Module",module)

    // const [expanded, setExpanded] = useState(null);


    // Fetch Transaction Types
    const { data: ttypes } = useAxios({
        url: '/transtypes',
        method: 'get'
    })

    // console.log(ttypes)
    const transtypes = Array.from(ttypes).filter(ttype => ttype.code === "10" || ttype.code === "20" || ttype.code === "50")
    // const transtypes = ttypes.filter(function(ttype) {
    //     return (ttype.code === "10" || ttype.code === "20");
    // });


    const invoiceType = Array.from(ttypes).filter(ttype => ttype.code === "10")
    // const invoiceType = ttypes.find(function(ttype) {
    //     return (ttype.code === "10");
    // });


    // FETCH ACCOUNT TYPES
    const { data: accounttypes } = useAxios({
        url: '/accounttypes',
        method: 'get'
    })

    // FETCH ACCOUNTS
    const { data: accts } = useAxios({
        url: '/accounts',
        method: 'get'
    })
   
    // console.log("ACCOUNTS", accts)

    const [accounts2, setAccounts2] = useState([]);

    // FETCH CLIENT PROFILE TO SET NEXT SERIAL NUMBER(S)

    const [clientprofile, setClientprofile] = useState([]);
    const [reference, setReference] = useState('');
    const client = localStorage.getItem('clientID');
    const clientProfileID = localStorage.getItem('clientProfile');


    // const { data: clientprofile } = useAxios({
    //     url: `/clientprofiles/${client}`,
    //     method: 'get'
    // })  

    async function fetchClientProfile () {
        try {
            const response = await authAxios.get(`/clientprofiles/${clientProfileID}`);
            setClientprofile(response.data)
            // console.log("RESPONSE", response)
            // console.log("CPROFILE", clientprofile)
        } catch (error) {
            console.error(error);
        }
    }
    // useEffect(() => {
    //     fetchClientProfile()
    //     console.log("USE EFFECT REF", reference)

    // }, [reference]); 

    // FETCH CUSTOMERS

    // const { data: customers } = useAxios({
    //     url: '/customers',
    //     method: 'get'
    // })

    const [customers, setCustomers] = useState([]);
    async function fetchCustomers () {
        try {
            const response = await authAxios.get('/customers/');
            setCustomers(Array.from(response.data.results))
        } catch (error) {
            console.error(error);
        }
    }
    // fetchCustomers()
    // console.log("CUSTOMERS", customers)



    const [accounts, setAccounts] = useState(accts);

    useEffect(() => {
        setAccounts(accts)
        if (response.status === 201){
            setReference("")
            handleDrawerClose()
        }
        fetchClientProfile()
        fetchCustomers()


        // setAccounts2(accts2)

    }, [accts, response]); 

    // const [accounts, setAccounts] = useState('');

    // const [account, setAccount] = useState('');
    // const [accounts, setAccounts] = useState(accts);
    // accts && setAccounts(accts)

    

    const { data: properties } = useAxios({
        url: '/properties',
        method: 'get'
    })

    // const properties = Object.keys(props.name);
    // const [properties, setProperties] = useState([]);

    // let properties =[]

    // const getProperties = () => {
    //     for(let i= 0; i < props.length; i++){
    //         properties.push("ID", props[i].id,"Name:", props[i].name); 

    //     }
    // }
    // getProperties()

    // console.log("PROPERTIES", properties)



    //Redirect back to Dashboard after successful post
    // const navigate = useNavigate();
    // const location = useLocation();
    const params = useParams();
    // const from = location.state?.from?.pathname || "/";
    // const from = location.state.from.pathname

    // console.log("LOCATION", location)
    // console.log("PARAMS", params)


    // useEffect(() => {
    //     if (response.status === 201){
    //         setReference("")
    //         handleDrawerClose()
    //     }
    //     fetchClientProfile()

    // }, [ response]); 

    // const moment = extendMoment(Moment);

    // const [value, setValue] = React.useState(new Date());
    // const [date, setDate] = useState(new Date());
    const [dt, setDt] = useState(new Date());

    const [account, setAccount] = useState('');
    const [account2, setAccount2] = useState('');


    // const [transCode, setTransCode] = useState('');

    const [type, setType] = useState('');
    const [amt, setAmt] = useState('');
    const [entryType, setEntryType] = useState('');
    const [memo, setMemo] = useState('');
    const [ref, setRef] = useState('');
    const [customer, setCustomer] = useState('');
    const [property, setProperty] = useState('');
    // const [prop, setProp] = useState('');



    const user = localStorage.getItem('userID');
    // const client = localStorage.getItem('clientID');

    const [referenceError, setReferenceError] = useState(false);
    const [amtError, setAmtError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [customerError, setCustomerError] = useState(false)
    const [memoError, setMemoError] = useState(false)

    
    const [showCustomers, setShowCustomers] = useState(false);
    const [showAccounts, setShowAccounts] = useState(false);
    const [showAccounts2, setShowAccounts2] = useState(false);
    const [showProperties, setShowProperties] = useState(false);

    const [accountLabel, setAccountLabel] = useState("Account")
    const [account2Label, setAccount2Label] = useState("")


    const handleTransType = (e) => {
        // setType(e.target.value)
        setTypeError(false)
        setReference('');
        setShowCustomers(false)
        setShowAccounts(false)
        setShowAccounts2(false)
        setShowProperties(false)

        console.log("TRANS TYPE",e.target.value)
        console.log("CUSTOMERS I", showCustomers)

        if (e.target.value==="10" || "20"){
          
            //ASSIGN DEFAULT/SELECTED CUSTOMER ID
            if (module === "CRM"){
                setCustomer(params.id)
            }

            //If the customer ID (state) is unknown based on source module
            if (module === "ACC"){
                setShowCustomers(true)
            }
        }


        if (e.target.value==="10"){

            setType(invoiceType.id)

            //Assign next invoice number
            setReference(clientprofile.invoice_no)
            // setReference(clientprofile.data.invoice_no)


            // const invoiceNo = "INV" + String(clientprofile.invoice_no).padStart(6, '0')
            const invoiceNo = clientprofile.invoice_prefix + String(clientprofile.invoice_no).padStart(6, '0')
            setReference(invoiceNo)

            //Set transaction type for invoices to to Debit
            setEntryType("D")

            //Hide and default to Control Account 
            if (clientprofile.debtors_control){
                // setShowAccounts(false)
                setAccount(clientprofile.debtors_control);
            }
            else{
                setShowAccounts(true)
                // Filter receivable accounts only
                const accts1 = accts.filter(acct => acct.type === "30200");
                setAccounts(accts1)
            }

        }
        // else if (e.target.value==="20"){

        if (e.target.value==="20"){
            setType(e.target.value)

            //ASSIGN THE NEXT RECEIPT NUMBER
            setReference(clientprofile.invoice_no)
            // setReference(clientprofile.data.invoice_no)

            const receiptNo = clientprofile.receipt_prefix + String(clientprofile.receipt_no).padStart(6, '0')
            setReference(receiptNo)

            //SET TRANSACTION ENTRY TYPE FOR RECEIPTS TO BANK/CASH ACCOUNTS
            setEntryType("D")


            console.log("TYPE")

            // //HIDE AND DEFAULT TO CONTROL ACCOUNT  
            // if (clientprofile.debtors_control){
            //     // setShowAccounts(false)
            //     setAccount(clientprofile.debtors_control);
            // }
            // else{
            //     setShowAccounts(true)
            //     // Filter receivable accounts only
            //     const accts1 = accts.filter(acct => acct.type === "30200");
            //     setAccounts(accts1)
            // }

            // FILTER BANK AND CASH ACCOUNTS ONLY 
            
            const acctype = accounttypes.find(accounttype => accounttype.code === "30100");
            console.log("ACC TYPE",acctype)

            // const accts1 = accts.filter(acct => acct.type === "30100");
            const accts1 = accts.filter(acct => acct.type === acctype.id);
            console.log("ACC1", accts1)

            setAccounts(accts1)
            // setShowCustomers(true)
            setShowAccounts(true)

        }

        if (e.target.value==="50"){
            setShowCustomers(false)
            setShowAccounts(true)
            setShowAccounts2(true)
            setShowProperties(true)

            setAccountLabel("Bank/Cash Account")
            setAccount2Label("Expense Account")
            setEntryType("C")

            // console.log("CUSTOMERS", showCustomers)

            // FILTER BANK AND CASH ACCOUNTS ONLY 
            const acctype = accounttypes.find(accounttype => accounttype.code === "30100");
            const accts1 = accts.filter(acct => acct.type === acctype.id);
            setAccounts(accts1)

            // CONTRA ACCOUNT - FILTER EXPENSE ACCOUNTS ONLY 
            const acctype2 = accounttypes.find(accounttype => accounttype.code === "20100");

            const accts2 = accts.filter(acct2 => acct2.type === acctype2.id);
            console.log("ACC", accts)
            console.log("ACC TYPE3", acctype2)
            console.log("ACC2", accts2)

            setAccounts2(accts2)


        }

        // else{
        //     setType(e.target.value)
        //     setAccounts(accts)
        //     setShowAccounts(true)
        // }

        const txntype = ttypes.find(function(ttype) {
            return (ttype.code === e.target.value);
        });
        setType(txntype.id)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setAmtError(false)

        if (reference === "" ) {
            // setTypeError(true)
            setReferenceError(true)
        }

        if (!amt > 0 ) {
            setAmtError(true)
        }

        if (type === "" ) {
            setTypeError(true)
        }

        if (memo === "" ) {
            setMemoError(true)
        }

        // console.log(reference, amt, type, memo)
        if (reference && amt && type && memo) {

            let entry_type = entryType
            
            //Change the format of Price field before saving
            let amount = 0
            // const amount = parseFloat(amt.replace(/,/g, ''));
            amount = parseFloat(amt.replace(/,/g, ''));
            // if (entry_type = "C") {
            //     amount = amount * -1
            // }

            //Change the format of transaction date before saving
            const date = moment(dt).format("YYYY-MM-DD")

            // const property =  prop.id

            const transaction = { client, customer, property, reference, ref, date, type, account, account2, amount, memo, entry_type, user };
            // console.log(transaction)

            fetchData({
                url: '/transactions/',
                method: 'post',
                data: transaction
              })

            
            // entry_type = "C"
            // const transaction1 = { client, customer, reference, ref, date, type, account, amount, memo, entry_type, user };
  
            // fetchData({
            //     url: '/transactions/',
            //     method: 'post',
            //     data: transaction1
            // })
              
        } 
    }


    return (


        
        /* // <div className="dashboard-container">
        //     <Sidebar />

        //     <div className="dashboard-main"> */

                /* <section className="dashboard-content"> */

        <FormDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}>

                <section className="dashboard-form">

                    <UserAlerts error={error} />

                    <div className="dashboard-content-header">
                        <h6 className="dashboard-content-pretitle">
                            Accounting
                        </h6>

                        <h1 className="dashboard-content-title">
                            New Transaction
                        </h1>
                    </div>


                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            error={typeError}
                            required
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                className="custom-select"
                                label="Type"
                                onChange={handleTransType}

                                // onChange={(e) => {
                                //     setTypeError(false)
                                //     setType(e.target.value)}
                                // }
                                defaultValue = ""
                            >
                                {transtypes && transtypes.map((transtype) => { 
                                    return(
                                    // <MenuItem key={transtype.code} value={transtype.code}>{transtype.name}</MenuItem>
                                    <MenuItem key={transtype.code} value={transtype.code}>{transtype.name}</MenuItem>

                                    )
                                })}   
                               
                            </Select>
                        </FormControl>
    
                        <TextField className="custom-input"
                            onChange={(e) => {
                                setReferenceError(false)
                                setReference(e.target.value)}
                            }
                            label="Reference" 
                            variant="outlined" 
                            fullWidth
                            required
                            value = {reference}
                            error={referenceError}
                        />

                        
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="Transaction Date"
                                // inputFormat="yyyy-mm-dd"
                                // value={dt}
                                value={dt}

                                // onChange={handleChange}
                                // setEnd(moment(range[1]).format("YYYY-MM-DD"));
                                // onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))} 
                                onChange={(newDate) => {
                                    // setDate(moment(newDate).format("YYYY-MM-DD"));
                                    setDt(moment(newDate).format("YYYY-MM-DD"));

                                  }}
                                // onChange={(e) => setDate(e.target.value)} 
                                renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                            />
                        </LocalizationProvider>

                        {showAccounts && 
                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            // error={typeError}
                            required
                        >
                            {/* <InputLabel>Account</InputLabel> */}
                            <InputLabel>{accountLabel}</InputLabel>

                            <Select
                                className="custom-select"
                                // label="Account"
                                label={accountLabel}

                                onChange={(e) => {
                                    // setTypeError(false)
                                    setAccount(e.target.value)}
                                }
                                defaultValue = ""
                            >
                                {accounts.map((account) => { 
                                    return(
                                    <MenuItem className="custom-select-list" key={account.id} value={account.id}>{account.name}</MenuItem>
                                    )
                                })}   
                               
                            </Select>
                        </FormControl>
                        }

                        {showAccounts2 && 
                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            // error={typeError}
                            required
                        >
                            {/* <InputLabel>Expense Account</InputLabel> */}
                            <InputLabel>{account2Label}</InputLabel>

                            <Select
                                className="custom-select"
                                // label="Account"
                                label={account2Label}

                                onChange={(e) => {
                                    // setTypeError(false)
                                    setAccount2(e.target.value)}
                                }
                                defaultValue = ""
                            >
                                {accounts2.map((account2) => { 
                                    return(
                                    <MenuItem className="custom-select-list" key={account2.id} value={account2.id}>{account2.name}</MenuItem>
                                    )
                                })}   
                               
                            </Select>
                        </FormControl>
                        }

                      


                        {showCustomers && 
                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            error={customerError}
                            required
                        >
                            <InputLabel>Customer</InputLabel>
                            <Select
                                className="custom-select"
                                label="Customer"
                                onChange={(e) => {
                                    setCustomerError(false)
                                    setCustomer(e.target.value)}
                                }
                                defaultValue = ""
                                // value={params.id}
                                error={customerError}
                                // readOnly
                            >
                                {customers.map((customer) => { 
                                    return(
                                    <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                    )
                                })}   
                               
                            </Select>
                        </FormControl>
                        }

                        <NumberFormat 
                            onChange={(e) => setAmt(e.target.value)} 
                            className="custom-input"
                            fullWidth
                            label="Amount" 
                            variant="outlined" 
                            customInput={TextField}
                            // format="254 (###) ######" mask="_"
                            thousandSeparator={true} 
                            // prefix={'KES '} 
                            required
                            error={amtError}

                        />

                        {/* {showProperties && 
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setProperty(newValue.id);
                                console.log("VALUE", newValue.id)
                              }}
                            options={properties}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Assign Expense to Property"  /> }
                        />
                        } */}


                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemoError(false)
                                setMemo(e.target.value)
                            }}
                            label="Memo"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                            error={memoError}
                        />

                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary" type="submit">
                                Save
                            </button>
                        </div>

                    </form>

                </section>
        {/* //     </div>
        // </div> */}

        </FormDrawer>
    ) 


}

export default TransactionNew;
