// import React from 'react';

import React, { useState } from 'react';
import axios from 'axios';

import { useParams } from "react-router-dom";
// import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
// import { FormHelperText } from '@mui/material';

// import logo from '../assets/stella-logo.svg'
import logo from '../assets/gesti-logo.svg'



function ResetPassword() {
  // const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  // console.log("api", process.env.REACT_APP_API_ENDPOINT)


  // let navigate = useNavigate();
  let params = useParams();
  // console.log(params)

  // function loginUser() {
  const [new_password1, setNewPassword1] = useState();
  const [new_password2, setNewPassword2] = useState();
  const [uid, setUid] = useState(params.uid);
  const [token, setToken] = useState(params.token)


  // const [token, setToken] = useState([])
  // const [token, setToken] = useState()


  // useEffect(() => {
  //   console.log("TOKEN", token);
  // },[token]);

  const [password1Error, setPassword1Error] = useState(false)
  const [password2Error, setPassword2Error] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault();

    setPassword1Error(false)
    setPassword2Error(false)

    if (!new_password1) {
      setPassword1Error(true)
    }
    if (!new_password2) {
      setPassword2Error(true)
    }

    if (new_password1 && new_password2) {
      const credentials = { new_password1, new_password2, uid, token };
      // console.log("credentials", credentials)

        axios.post("/dj-rest-auth/password/reset/confirm/", credentials )
        // axios.post(`/password/reset/${uid}/${token}/`, credentials )
        // axios.post(`${API_ENDPOINT}/token/`, credentials )
        .then(res => {
            // setToken(res.data.access);
        })
        .catch(function (error) {
            console.log("error", error);
        });

      }
    }

      // axios.interceptors.response.use(function (response) {
  
      //   return response;
      // }, function (error) {
      //   return Promise.reject(error);
      // });
  

  return(
    // <div className="container">
    <div className="container-fluid">

      {/* <div className="container-auth"> */}
        {/* <div className="auth-img">
          <div className="auth-img-title">
            Change Password
          </div>
          <div className="auth-img-subtitle">
            #1 Accommodation Management Platform
          </div>
        </div> */}
        <div className="auth-form">


          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        
            <div className='auth-form-brand'>
              <img src={logo} alt="brand logo"/>  

            </div>

            <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="New Password"
                type="password"
                name="password"
                // autoComplete="email"
                autoFocus
                onChange={e => setNewPassword1(e.target.value)}
                error={password1Error}
              />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password1"
              label="Confirm Password"
              type="password"
              id="password2"
            //   autoComplete="current-password"
              onChange={e => setNewPassword2(e.target.value)}
              error={password2Error}
            />
            {/* <FormHelperText id="component-helper-text">
                <Link to="/">
                    Forgot password?
                </Link>
            </FormHelperText> */}
            <div className='auth-form-button'>
              <button className='btn btn-primary' type="submit">Reset Password</button>
            </div>
          </form>
        </div>
      </div>

  )
}

export default ResetPassword