import React from "react";

import axios from "axios";
import useAxios from "./useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';


// import { InputLabel } from "@mui/material";
// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
// import { Select } from "@mui/material";


import moment from 'moment';
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';


// import Sidebar from "./Sidebar";
import UserAlerts from "./UserAlerts";
// import LayoutForm from "./LayoutForm";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
// import FormDrawer from "./FormDrawer";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomSnackBar from "./CustomSnackBar";
import authAxios from "./authAxios";

const CheckIn = ({isDrawerOpen, handleDrawerClose }) => {

    // const location = useLocation();
    const params = useParams()
    const {error, response, data, fetchData}  = useAxios();


    // const unit = location.state.unit;
    // const [property, setProperty] = useState(location.state.property);
    // const property = location.state.property;

    //Axios
    // FETCH DEFAULT VALUES
    // const [booking, setBooking] = useState(params.id);

    const booking = params.id;
    const [customer, setCustomer] = useState('');
    const [name, setName] = useState('');
    const [unit, setUnit] = useState('');
    const [property, setProperty] = useState('');

   
    async function fetchDefaultValues () {
        try {
            const response = await authAxios.get(`/bookings/${params.id}`);
            setCustomer(response.data.customer)
            setUnit(response.data.unit)
            const response1 = await authAxios.get(`/customers/${response.data.customer}`);
            setCustomer(response1.data.id)
            setName(response1.data.name)
            const response2 = await authAxios.get(`/units/${response.data.unit}`);
            const response3 = await authAxios.get(`/properties/${response2.data.property}`);
            setProperty(response3.data.name+" "+response2.data.reference)
        } catch (error) {
            console.log(error);
        }
    }
    // function fetchDefaultValues () {
    //     fetchData({
    //         url: `/bookings/${params.id}`,
    //         method: 'get',
    //         // data: activebooking
    //     })
    //     // setCustomer(activebooking.customer)
    //     // setUnit(activebooking.unit)

    //     console.log("ACTIVE BOOKING", response)
    // }

    // const [customer1, setCustomer1] = useState('');
    // async function fetchCustomer () {
    //     try {
    
    //         // fetchData({
    //         //     url: `/customers/${customer}`,
    //         //     method: 'get',
    //         // })
    //         const response = await authAxios.get(`/customers/${customer}`);
           
    // //         // console.log("ACTIVE CUSTOMER", activecustomer1)
    //         console.log("RESPONSE", response)
    //         // console.log("DATA", data)

    //         setName(response.data.name)


    //     }
    //     catch (error) {
    //         console.log(error);
    //     }

    // }


    // const { data: activebooking } = useAxios({
    //     url: `/bookings/${params.id}`,
    //     method: 'get'
    // })
    // console.log("ACTIVE BOOKING", activebooking)
    // console.log("CUSTOMER", activebooking.customer)
    // const cust = activebooking.customer

    // console.log("CUST", cust)
    // console.log("CUSTOMER", customer)


    // const [customer1, setCustomer1] = useState('');

    // const { data: activecustomer } = useAxios({
    //     url: `/customers/${customer}`,
    // //     // url: `/customers/${cust}`,
    //     method: 'get',
    //     customer: customer
    // })

    // console.log("ACTIVE CUSTOMER", activecustomer)


    const navigate = useNavigate();
    const [dt, setDt] = useState(new Date());
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [dtError, setDtError] = useState(false)

    //Handle snackbar for success feedback to the user
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSnackBarClose = () => {
        // console.log("DRAWER CLOSE")
        setSuccess(false)
        };

    const handleSubmit = (e) => {
        e.preventDefault();

        // setCustomerError(false)

        if (dt === '' || dt === null ) {
            setDtError(true)
        }

        console.log("ERROR",dtError)

        if (dt) {
    
            // const price = parseFloat(amount.replace(/,/g, ''));
            // console.log("DT",dt)
            // const checkin = moment(dt).format("YYYY-MM-DD")
            const checkin = moment(dt).format("YYYY-MM-DD HH:mm:ss")

            const stay = { client, booking, unit, customer, checkin, memo, user };

            // console.log("stay:", stay)
         
            fetchData({
                url: '/stays/',
                method: 'post',
                data: stay
              })
            .then(response=>{
                // console.log(response)
                setSuccess(true)
                setSuccessMessage("Guest check-in successful!")
                // navigate('/bookings/')

            })
        } 
    
    }

    useEffect(() => {
        fetchDefaultValues()

        //Redirect back to Bookings after successful checkin
        if (response.status === 201 && success === false){
            navigate('/bookings/')
        }    
        // if (activebooking.customer){
        //     setCustomer(activebooking.customer)
        //     // setName(activebooking.customer_name)
        //     fetchCustomer()
        //     // console.log("RESPONSE", response)
        //     // setName(response.data.name)


        // }
        // // setCustomer("33")

        // // fetchDefaultValues()
        // // setCustomer1(activecustomer)

        // console.log("USE EFFECT ACTIVE BOOKING", activebooking)
        // // console.log("USE EFFECT ACTIVE BOOKING CUSTOMER", activebooking.customer)
        // console.log("USE EFFECT CUSTOMER", customer)
        // // console.log("USE EFFECT ACTIVE CUSTOMER", activecustomer)
        // // console.log("USE EFFECT CUSTOMER ONE1", customer1)



        // setUnit(activebooking.unit)
        // setName(activecustomer.name)

    }, [response.status, success]); 
    // }, [activebooking, response, success]); 



    
    return(

        <Layout>

        <div className="dashboard-main">


            <section className="dashboard-form">

                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Check-In" dashboardPreTitle="Property Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <TextField className="custom-input"
                            disabled
                            value={name}
                            label="Customer" 
                            variant="outlined" 
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />

                        <TextField className="custom-input"
                            disabled
                            value={property}
                            label="Property" 
                            variant="outlined" 
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                // renderInput={(props) => <TextField {...props} />}
                                renderInput={(params) => <TextField autoFocus fullWidth required className="custom-input" {...params} error={dtError} />}

                                label="Check-In Date/Time"
                                value={dt}
                                onChange={(newValue) => {
                                    setDtError(false)
                                    setDt(newValue);
                                }}
                            />
                        </LocalizationProvider>


                        <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Comments"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                            // required
                            // error={detailsError}
                        /> 

                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary" type="submit">
                                Save
                            </button>
                        </div>

                        <CustomSnackBar success={success} successMessage={successMessage} handleSnackBarClose={handleSnackBarClose}/>

                </form>

            </section>
        
            </div>

    </Layout>    
    )
}

export default CheckIn;