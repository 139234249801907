import React from "react";
// import axios from "axios";
// import useAxios from "./useAxios";
import useAxios from "../components/useAxios";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";
// import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";

// import UserAlerts from "./UserAlerts";
import UserAlerts from "../components/UserAlerts";

// import DashboardHeader from "./DashboardHeader";
import DashboardHeader from "../components/DashboardHeader";
import FormDrawer from "../components/FormDrawer";
import ReportDrawer from "../components/ReportDrawer";
// import useRedirect from "./useRedirect";

function OccupancyFilter({isDrawerOpen, handleDrawerClose}) {
// function LeadNew() {
    const {response, error, fetchData}  = useAxios();
    // const {redirect} = useRedirect(error, response);

    useEffect(() => {
        // console.log("EFFECT", response)
        if (response.status === 201){
            handleDrawerClose()
            navigate('/leads/');
        }    
    }, [ response.status]); 

    // Fetch Lead Sources
    const { data: leadsources } = useAxios({
        url: '/leadsources',
        method: 'get'
    })

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [source, setSource] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [type, setType] = useState('');
    const [tag, setTag] = useState('LEAD');
    const [memo, setMemo] = useState('');


    // const [id_type, setId_type] = useState('');
    // const [id_number, setId_number] = useState('');
    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');


    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [sourceError, setSourceError] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(name, institution, email)

        if (source === '') {
            setSourceError(true)
        }

        if (name === '') {
            setNameError(true)
        }

        if (mobile === '' ) {
            setMobileError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        if (source && name && mobile && email) {
            // const telephone = mobile.replace(/(|)|" "/g,"")
            // const tel = mobile.toString()
            const telephone = mobile.replace(/[()" "]/g,"")


            // setMobile({mobile})
            // console.log(mobile, tel, telephone)

            const lead = { client, type, source, name, address, email, telephone, memo, tag, user };
            console.log(lead)

            fetchData({
                // url: '/leads/',
                url: '/contacts/',
                method: 'post',
                data: lead
            })
            // .then(()=>
            // {
            //     console.log(response)
            // //     if (response.status === 201){
            // //         handleDrawerClose()
            // //         navigate('/leads/')
            // //     }
            // })
        } 
    
    }

    return(

   
        // <FormDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}>

        <ReportDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}>

            <section className="dashboard-form">
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Filter" dashboardPreTitle="Occupancy Report" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <FormControl 
                        variant="outlined" 
                        fullWidth 
                        className="custom-input"
                        required
                        error={sourceError}
                    >
                        <InputLabel>Lead Source</InputLabel>
                        <Select
                            className="custom-select"
                            label="Select Period"
                            required
                            onChange={(e) => {
                                setSourceError(false)
                                setSource(e.target.value)}
                            }
                            // error={sourceError}
                            defaultValue = ""
                        >
                            {leadsources.map((leadsource) => { 
                                return(
                                    <MenuItem key={leadsource.id} value={leadsource.id}>{leadsource.name}</MenuItem>
                                )
                            })}       
                        </Select>
                    </FormControl>
                       


                    <NumberFormat 
                        onChange={(e) => {
                            setMobileError(false)
                            setMobile(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Mobile" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) ######" mask="_"
                        required
                        error={mobileError}
                    />

                    <TextField className="custom-input"
                        onChange={(e) => setAddress(e.target.value)}
                        label="Start" 
                        variant="outlined" 
                        fullWidth
                        required
                        // error={mobileError}
                    />
                    <TextField className="custom-input"
                        onChange={(e) => setAddress(e.target.value)}
                        label="End" 
                        variant="outlined" 
                        fullWidth
                        required
                        // error={mobileError}
                    />

                       

                   


                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Report
                        </button>
                    </div>


                </form>

            </section>

    </ReportDrawer>

    )

}


export default OccupancyFilter;