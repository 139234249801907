import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import useAxios from "./useAxios";
import authAxios from "./authAxios";

// import useAxiosInterceptor from "./useAxiosInterceptor";

import { Link } from "react-router-dom";

import Sidebar from "./Sidebar"
import Pagination from "./Pagination";
import Pillchip from "./Pillchip";

import { Card, CardContent, Tab, TableSortLabel, Tabs } from "@mui/material";
import TabContext from '@mui/lab/TabContext';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';



import DashboardHeader from "./DashboardHeader";

import { useMediaQuery } from 'react-responsive'
import Layout from "./Layout";
import BookingList from "./BookingList";
import { TabList, TabPanel } from "@mui/lab";
import { SettingsInputAntennaTwoTone } from "@mui/icons-material";

function Bookings(){
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [isCheckIn, setIsCheckIn] = useState(true)

    //Axios
    // const { data: bookings, loading } = useAxios('/bookings')
    // const {response, error } = useAxiosInterceptor();
    const { data: bookings, loading } = useAxios({
        url: '/bookings',
        method: 'get'
    })

    const confirmed = bookings.filter(booking => booking.status === "20")

    // console.log("CONFIRMED", confirmed)
    const checkedin = []

    // FETCH BOOKED UNITS
    const [units, setUnits] = useState([]);
    // const [stays, setStays] = useState([]);

    // async function fetchBookedUnits () {
    //     try {
    //         const response = await authAxios.get('/stays/');
    //         setStays(response.data)
    //         for(let i= 0; i < stays.length; i++){
    //             const checkedin1 = bookings.find(booking => booking.id === parseInt(stays[i].booking))
    //             checkedin.push = bookings.find(booking => booking.id === parseInt(stays[i].booking))
    //             console.log("CHECKEDIN", checkedin1)
    //             console.log("ID", stays[i].booking)

    //         }


    //         console.log("BOOKED", checkedin)
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }


    const { data: stays } = useAxios({
        url: "/stays",
        method: 'get'
    })
    // console.log("Stays", stays)
    stays.map((stay)=>{
        // checkedin.push = bookings.find(booking => booking.id === parseInt(stay.booking))
        checkedin.push(bookings.find(booking => booking.id === parseInt(stay.booking)))

    })
    // console.log("CHECKED IN", checkedin)

    // useEffect(() => {
    //     // fetchBookedUnits()

    // //  }, [stays.length]);
    // },[checkedin]);





    // for(let i= 0; i < customers.length; i++){
    //     cntCustomers = cntCustomers + 1
    // }


    const [removeBorder, setRemoveBorder] = useState(true);

    const [value, setValue] = useState('one');

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // PAGINATION
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const handlePageChange = (event, value) => {
        setPage(value);
        console.log("PAGE", page)
        // fetchTransactions(page)
    };

    // const [loading, setLoading] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(5);

     // Get current posts
    //  const indexOfLastPost = currentPage * postsPerPage;
    //  const indexOfFirstPost = indexOfLastPost - postsPerPage;
    //  const currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
 
     // Change page
    //  const paginate = pageNumber => setCurrentPage(pageNumber);


    return (
        // <Layout>

            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle="Bookings" dashboardPreTitle="Property Management" removeBorder={removeBorder}/>

                    <TabContext 
                        value={value}
                        >
                        {/* <Tabs
                        
                            value={value}
                            onChange={handleTabChange}
                        > */}
                        <TabList className="custom-tab-container" onChange={handleTabChange}>
                            <Tab value="one" label="All" className="custom-tab" />   
                            <Tab value="two" label="Confirmed" className="custom-tab"/>
                            <Tab disabled value="three" label="Arriving Soon" className="custom-tab"/>
                            <Tab value="four" label="Checked In" className="custom-tab" />
                        </TabList> 
                        <TabPanel value="one" className="custom-tab-panel">
                            <BookingList 
                                module="PMS" 
                                bookings={bookings} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            />
                        </TabPanel>
                        <TabPanel value="two" className="custom-tab-panel">
                            <BookingList 
                                module="PMS" 
                                bookings={confirmed} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            />
                        </TabPanel>
                        <TabPanel value="thee">Item Three</TabPanel>
                        <TabPanel value="four" className="custom-tab-panel">
                            <BookingList 
                                module="PMS" 
                                bookings={checkedin} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                                isCheckIn={isCheckIn}
                            />
                        </TabPanel>
                        {/* </Tabs> */}
                    </TabContext>
                
                </section>
            </div>
        
        // {/* </Layout> */}


    )
}
export default Bookings