import React from "react";
import axios from "axios";
import useAxios from "./useAxios";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";

import UserAlerts from "./UserAlerts";

import { TextField } from "@mui/material";
import { Select } from "@mui/material";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import FormDrawer from "./FormDrawer";

// function UnitNew() {
const UnitNew = ({isDrawerOpen, handleDrawerClose}) => {
    const location = useLocation();
    const navigate = useNavigate();  
    const params = useParams();

    //Axios post hook
    const {response, error, fetchData}  = useAxios();

     // const property = location.state.property
     const property = params.id


    //REDIRECT BACK TO PREVIOUS PAGE AFTER SUCCESSFUL POST
    useEffect(() => {
        if (response.status === 201){
            // navigate(`/property/${location.state.property}`);
            // navigate(`/property/${property}`, {state:{origintab:"UNITS"}});
            handleDrawerClose()
        }    
    }, [ response]); 


    // FETCTH UNIT TYPES
    const [unittypes, setUnitTypes] = useState([]);
    // const { data: unittypes, loading } = useAxios({
    //     // url: `/unittypes/?property=${location.state.property}`,
    //     url: `/unittypes/?property=${property}`,
    //     method: 'get'
    // })

    async function fetchUnitTypes () {
        try {
            const response = await axios.get(`/unittypes/?property=${property}`);
            setUnitTypes(response.data)

            // console.log("RESPONSE",response)
            // console.log("DATA", unittypes)
        } catch (error) {
            console.error(error);
        }
    }

    // useEffect(()=>{
    //     fetchUnitTypes()
    // },[])


    // FETCH PROPERTIES
    const { data: properties } = useAxios({
        url: '/properties',
        method: 'get'
    })
    
    const client = localStorage.getItem('clientID');
    const [reference, setReference] = useState('');
   

    const [type, setType] = useState('');
    const [capacity, setCapacity] = useState('');
    const [memo, setMemo] = useState('');

    const [referenceError, setReferenceError] = useState(false)
    const [typeError, setTypeError] = useState(false)
    const [capacityError, setCapacityError] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();

        if (reference === '') {
            setReferenceError(true)
        }

        if (type === '') {
            setTypeError(true)
        }

        if (capacity < 1 ) {
            setCapacityError(true)
        }

        if (reference && type && capacity) {
            console.log(reference, capacity)
    
            //Change the format of Price field before saving
            // const price = parseFloat(price1.replace(/,/g, ''));

            const unit = { client, reference, property, type, capacity, memo };
        
            fetchData({
                  url: '/units/',
                  method: 'post',
                  data: unit
                })
        } 
    
    }

    //Variable for closing alerts 
    // const [open, setOpen] = useState(true);

    return(
       
        <FormDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}>
        

            <div className="dashboard-form">
                <UserAlerts error={error} />

                <DashboardHeader dashboardTitle="Add Unit" dashboardPreTitle="Property Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setReferenceError(false)
                                setReference(e.target.value)
                            }}
                            label="Reference" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={referenceError}
                        />

                        <FormControl variant="outlined" fullWidth className="custom-input">
                            <InputLabel>Property</InputLabel>
                            <Select
                                className="custom-select"
                                label="Property"
                                // onChange={(e) => setProperty(e.target.value)}
                                defaultValue = {property}
                                value={property}
                                readOnly
                            >
                                {properties.map((property) => { 
                                    return(
                                        <MenuItem key={property.id} value={property.id}>{property.name}</MenuItem>
                                    )
                                })}       
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth className="custom-input">
                            <InputLabel>Type</InputLabel>
                            <Select
                                className="custom-select"
                                label="Type"
                                onOpen={fetchUnitTypes}
                                onChange={(e) => {
                                    setTypeError(false)
                                    setType(e.target.value)
                                }}
                                defaultValue = ""
                                required
                                error={typeError}
                            >
                                {unittypes.map((unittype) => { 
                                    return(
                                    <MenuItem key={unittype.id} value={unittype.id}>{unittype.name}</MenuItem>
                                    )
                                })}   
                               
                            </Select>
                        </FormControl>

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setCapacityError(false)
                                setCapacity(e.target.value)
                            }}
                            // id="outlined-number"
                            label="Capacity/Pax"
                            type="number"
                            fullWidth
                            error={capacityError}
                        />
                        
                        <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                            // required
                            // error={detailsError}
                        />

                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary" type="submit">
                                Save
                            </button>
                        </div>

                </form>

            </div>
        {/* </div>
        </Layout> */}
        </FormDrawer>

    )

}

export default UnitNew;
