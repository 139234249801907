import React from "react";
// import axios from "axios";
import useAxios from "./useAxios";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";
// import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";

// import Sidebar from "./Sidebar";
import UserAlerts from "./UserAlerts";
// import LayoutForm from "./LayoutForm";
// import Layout from "./Layout";

import DashboardHeader from "./DashboardHeader";
import FormDrawer from "./FormDrawer";
// import useRedirect from "./useRedirect";

function LeadNew({isDrawerOpen, handleDrawerClose}) {
// function LeadNew() {
    const {response, error, fetchData}  = useAxios();
    // const {redirect} = useRedirect(error, response);

    useEffect(() => {
        // console.log("EFFECT", response)
        if (response.status === 201){
            handleDrawerClose()
            navigate('/leads/');
        }    
    }, [ response.status]); 

    // Fetch Lead Sources
    const { data: leadsources } = useAxios({
        url: '/leadsources',
        method: 'get'
    })

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [source, setSource] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [type, setType] = useState('');
    const [tag, setTag] = useState('LEAD');
    const [memo, setMemo] = useState('');


    // const [id_type, setId_type] = useState('');
    // const [id_number, setId_number] = useState('');
    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');


    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [sourceError, setSourceError] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(name, institution, email)

        if (source === '') {
            setSourceError(true)
        }

        if (name === '') {
            setNameError(true)
        }

        if (mobile === '' ) {
            setMobileError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        if (source && name && mobile && email) {
            // const telephone = mobile.replace(/(|)|" "/g,"")
            // const tel = mobile.toString()
            const telephone = mobile.replace(/[()" "]/g,"")


            // setMobile({mobile})
            // console.log(mobile, tel, telephone)

            const lead = { client, type, source, name, address, email, telephone, memo, tag, user };
            console.log(lead)

            fetchData({
                // url: '/leads/',
                url: '/contacts/',
                method: 'post',
                data: lead
            })
            // .then(()=>
            // {
            //     console.log(response)
            // //     if (response.status === 201){
            // //         handleDrawerClose()
            // //         navigate('/leads/')
            // //     }
            // })
        } 
    
    }

    return(

    // <div className="dashboard-container">
    // <Layout>

    //     <div className="dashboard-main">
        <FormDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}>


            <section className="dashboard-form">
                {/* Error Messaging */}
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Lead" dashboardPreTitle="CRM" />

                {/* <div className="dashboard-content-header">

                    <h6 className="dashboard-content-pretitle">
                        CRM
                    </h6>

                    <h1 className="dashboard-content-title">
                        Add Lead
                    </h1>
                </div> */}

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                       
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""
                        onChange={(e) => setType(e.target.value)}
                        className="custom-input"
                    >
                        <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Individual" />
                        <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Company" />
                    </RadioGroup>
                        
                        <TextField className="custom-input"
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                            }}
                            label="Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setEmailError(false)
                                setEmail(e.target.value)
                            }}
                            label="Email" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={emailError}
                        />

                        {/* <TextField className="custom-input"
                            onChange={(e) => {
                                setMobileError(false)
                                setMobile(e.target.value)
                            }}
                            label="Mobile" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={mobileError}
                        /> */}

                        <NumberFormat 
                            onChange={(e) => {
                                setMobileError(false)
                                setMobile(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Mobile" 
                            variant="outlined" 
                            customInput={TextField}
                            format="### (###) ######" mask="_"
                            required
                            error={mobileError}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => setAddress(e.target.value)}
                            label="Address" 
                            variant="outlined" 
                            fullWidth
                            // required
                            // error={mobileError}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Memo"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                            // required
                            // error={messageError}
                        />

                    <FormControl 
                        variant="outlined" 
                        fullWidth 
                        className="custom-input"
                        required
                        error={sourceError}
                    >
                        <InputLabel>Lead Source</InputLabel>
                        <Select
                            className="custom-select"
                            label="Lead Source"
                            required
                            onChange={(e) => {
                                setSourceError(false)
                                setSource(e.target.value)}
                            }
                            // error={sourceError}
                            defaultValue = ""
                        >
                            {leadsources.map((leadsource) => { 
                                return(
                                    <MenuItem key={leadsource.id} value={leadsource.id}>{leadsource.name}</MenuItem>
                                )
                            })}       
                        </Select>
                    </FormControl>


                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Save
                        </button>
                    </div>


                </form>

            </section>
        {/* </div>

        </Layout> */}
    </FormDrawer>

    )

}


export default LeadNew;
