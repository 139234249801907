import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAxios from "./useAxios";
import axios from "axios";

// import useAxiosInterceptor from "./useAxiosInterceptor";

import { Link } from "react-router-dom";

// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';

// import Pillchip from "./Pillchip";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent, Menu, CardActions } from "@mui/material";
// import { MenuItem } from "@mui/material";
// import { IconButton } from "@mui/material";
import { Button } from "@mui/material";
// import Card from '@mui/material/Card';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';


import { useMediaQuery } from 'react-responsive'
// import DashboardHeader from "./DashboardHeader";
// import Layout from "./Layout";
import ThreeDotsMenu from "./ThreeDotsMenu";
// import { PanoramaSharp } from "@mui/icons-material";
import UnitNew from "./UnitNew";
import { useEffect } from "react";

const UnitList = ({units, fetchUnits, handlePageChange, page, pagecount}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
   
    const navigate = useNavigate();
    const params = useParams();
    const property = params.id

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        // console.log("DRAWER CLOSE")
        setIsDrawerOpen(false)
    };

    useEffect(()=>{
        // fetchUnits()

        // if (!isDrawerOpen) {
        //     fetchUnits()
        // }

    },[isDrawerOpen])


   const threeSubMenu = [
       {
           id: 1,
           menu: "View",
           url: "/unit/",
       }, 
       // {
       //     id: 2,
       //     menu: "Booking",
       //     url: "/bookingnew/",
       // }
   ];

    
    
   

   return (
        <>
            {isMobile? 
            <>
            
            {units && units.map(unit => ( 

            <Card className="dashboard-card" key={unit.id}>
                {/* <CardHeader
                    className="dashboard-card-header"
                    title={unit.reference} 
                    subheader={unit.property_name}
                /> */}

                <CardContent className="dashboard-card-content">
                    <div className="dashboard-card-title">{unit.reference}</div>
                    <div className="dashboard-card-subtitle">{unit.property_name}</div>                            
                    {/* <p>Price: {unit.price}</p> */}
                    {/* {unit.type} */}
                    {/* <Typography>Price: {unit.price}</Typography> */}
                    <p>{unit.unit_type}</p>    
                    <p>Capacity: {unit.capacity}</p>    

                    {/* <p>{unit.price}</p>     */}  
                    {/* <Pillchip label={unit.room_status} /> */}


                </CardContent>
                <CardActions>
                    <Button size="small">Booking</Button>
                </CardActions>
            </Card>
            ))}

            </>

            :
            // If Device is not mobile, display data using a table
            // {units && units.map(unit => ( 

            <table className="styled-table">
                <thead>
                    <tr >
                        <th>Reference</th>
                        <th>Property</th>
                        <th>Type</th>
                        {/* <th>Status</th> */}
                        {/* <th>Price</th> */}
                        <th>Capacity</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                {units && units.map(unit => (
                    <tbody key={unit.id}>
                        <tr >   
                            <td data-label="Reference">{unit.reference}</td>

                            <td data-label="Property">{unit.property_name}</td>
                            <td data-label="Type">{unit.unit_type}</td>
                            {/* <td data-label="Price">{Number(unit.price).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td> */}
                            <td data-label="Capacity">{unit.capacity}</td>
                            <td data-label="Status">{unit.room_status}</td>


                            {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /> */}
                            <td className='styled-table-menu'>
                                {/* <ThreeDotsMenu data={unit} /> */}
                                <ThreeDotsMenu data={unit} threeSubMenu={threeSubMenu} />
                            </td>

                        </tr>
                        {/* </Link> */}

                    </tbody>

                ))}
                
            </table>
            }
            <UnitNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />

            <Pagination 
                className="custom-mui-pagination"
                // color="primary" 
                count={pagecount} 
                page={page} 
                onChange={handlePageChange} 
            />

            {/* <Link to="/unitnew" className="nav-link"> */}
                <Fab 
                    className="fab" 
                    color="primary" 
                    aria-label="add"
                    onClick = {()=>{
                        {isMobile? 
                            navigate('/unitnew', {state:{property:params.id}})
                        :    
                        handleDrawerOpen()
                        }
                    }}
                >
                    <AddIcon />
                </Fab>
            

       </>


   )

}

export default UnitList