import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "./useAxios";


import { TextField, Switch, FormGroup, FormControlLabel } from "@mui/material";
// import Header from "./Header";
import LayoutForm from "./LayoutForm";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import UserAlerts from "./UserAlerts";
// import Switch from "@mui/material";

function PropertyNew() {
    //Axios post hook
    const {error, response, fetchData}  = useAxios();

    const navigate = useNavigate();

    useEffect(() => {
        // console.log("Response", response)
        if (response.status === 201){
            navigate('/properties/');
        }    
    }, [ response.status]); 

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [multi, setMulti] = useState('');
    const client = localStorage.getItem('clientID');

    const [nameError, setNameError] = useState(false)
    const [addressError, setAddressError] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(false)
        setAddressError(false)

        if (name === '') {
            setNameError(true)
        }

        if (address === '') {
            setAddressError(true)
        }

        if (name && address) {
            // console.log(reference, capacity)
    
            const property = { client, name, address, multi };

            // console.log("room", room)
        
            fetchData({
                  url: '/properties/',
                  method: 'post',
                  data: property
                })

            
        } 
    }



    return (

        // <Layout>




        <div className="dashboard-main">


            <section className="dashboard-form">
                <UserAlerts error={error} />

                <DashboardHeader dashboardTitle="Add Property" dashboardPreTitle="Property Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <TextField className="custom-input"
                        onChange={(e) => setName(e.target.value)}
                        label="Name" 
                        variant="outlined" 
                        // color="secondary" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <TextField className="custom-input"
                        onChange={(e) => setAddress(e.target.value)}
                        label="Address" 
                        variant="outlined" 
                        // color="secondary" 
                        fullWidth
                        required
                        error={addressError}
                    />

                    {/* <FormGroup className="custom-input">
                        <FormControlLabel control={<Switch onChange={(e) => setMulti(e.target.value)} />} label="Multiple Units" />
                    </FormGroup>  */}

                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary" type="submit">
                            Save
                        </button>
                    </div>
                </form>
            </section>
           

        </div>

        // </Layout>


       
        // </div>

    )

}

export default PropertyNew;
