import React from "react"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { fab } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLocationDot, faLocationPin, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";

// import {BrowserRouter, Routes, Route, Link} from "react-router-dom";

//import Contact from "./Contact";


function Footer() {
    return (
        <>
        <footer className="footer">
            <div className="footer-logo">
                <a href="https://twitter.com/getgesti" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} /> 
                </a>
                <a href="https://www.facebook.com/getgesti/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"></i>
                    <FontAwesomeIcon icon={faFacebook} /> 
                </a>
                <a href="/">
                    <FontAwesomeIcon icon={faInstagram} /> 
                </a>
            </div>

            <div className="footer-section">
                <div className="footer-section-title">Solutions</div>
                <ul className="footer-list">
                    <li><a href="/">AirBnB/Vacation Homes</a></li>
                    <li><a href="/">Serviced Apartments</a></li>
                    <li><a href="/">Hotels and Lodges</a></li>
                    {/* <li><a href="/">Bed and Breakfast Hotels</a></li> */}
                </ul>
            </div>

            <div className="footer-section footer-section-company">
                <div className="footer-section-title">
                    Company
                </div>
                <ul className="footer-list">
                    <li><a href="/">About</a></li>
                    <li><a href="/">Team</a></li>
                    <li><a href="/">Careers</a></li>
                </ul>
            </div>

            <div className="footer-section footer-section-legal">
                <div className="footer-section-title">Legal</div>
                <ul className="footer-list">
                    <li><a href="/">Privacy Notice</a></li>
                    <li><a href="/">Terms and Conditions</a></li>
                </ul>
            </div>

            <div className="footer-section">
                <div className="footer-section-title">Connect</div>
                    <ul className="footer-list">
                        <li>
                            <FontAwesomeIcon icon={faLocationDot} /> 
                            Unipen Building, Argwings Kodhek Rd, Nairobi, Kenya
                        </li>
                        <li>
                            <a href="tel:+254202028950">
                                <FontAwesomeIcon icon={faPhoneSquare} /> 
                                +254.20.2028950
                            </a>
                        </li>
                        <li>
                            <Link to="/contact">
                                <FontAwesomeIcon icon={faEnvelope} /> 
                                Email
                            </Link>

                        </li>
                    </ul>

                    {/* <ul className="footer-list">
                        <Link to="/contact">Email</Link>
                    </ul> */}

                    {/* <ul className="footer-list"> */}
            </div>
            
        </footer>
        <section className="footer-copyright">
            © 2022 gesti. All rights reserved.
        </section>
        </>
    )
  }

  export default Footer;
