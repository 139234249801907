import React from "react";
import axios from "axios";
import useAxios from "./useAxios";
import authAxios from "./authAxios";
import useAxiosInterceptor from '../components/useAxiosInterceptor';

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

import Sidebar from "./Sidebar";
import UserAlerts from "./UserAlerts";
// import LayoutForm from "./LayoutForm";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";



function CustomerEdit() {
    const params = useParams()
    const [isUpdated, setIsUpdated] = useState(false)


    const {fetchData, response, error}  = useAxios();
    // const { error: err, response: res } = useAxiosInterceptor();
    // const {redirect} = useRedirect(error, response);

    // FETCH CUSTOMER
    // const { data: customer } = useAxios({
    //     url: `/customers/${params.id}`,
    //     method: 'get'
    // })  

    const [dt, setDt] = useState(new Date());

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    // const [institution, setInstitution] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [gender, setGender] = useState('');
    const [contact, setContact] = useState('');

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    const [customer, setCustomer] = useState([]);


    async function fetchCustomer () {
        try {
            // const response = await axios.get(`/customers/${params.id}`);
            const response = await authAxios.get(`/customers/${params.id}`);

            setCustomer(response.data)
            setName(response.data.name)
            setEmail(response.data.email)
            setMobile(response.data.telephone)
            setAddress(response.data.address)
            setId_type(response.data.id_type)
            setId_number(response.data.id_number)
            setGender(response.data.gender)
            setDt(response.data.birth_date)
            setContact(response.data.contact)


            // console.log("RESPONSE",response.data)

        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        
        // if (response.status === 200){
        //     navigate('/customers/');
        // }    
        fetchCustomer()
    // }, [ response.status, error]); 
    }, []); 


    const navigate = useNavigate();

    // const [name, setName] = useState('');
    // const [name, setName] = useState(customer.name);

    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)

    // const idLength = id_number.length
    // console.log(id_number.length)

    const handleChange = (event) => {
        setName(event.target.value);
      };

    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (mobile === '' ) {
            setMobileError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        
        // console.log("ID ERROR",idNumberError)

        if (name && mobile && email && idNumberError === false ) {
            const telephone = mobile.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            const birth_date = moment(dt).format("YYYY-MM-DD")

            const customer = { client, contact, name, address, email, telephone, id_type, id_number, gender, birth_date, user };
            // console.log(customer)

            fetchData({
                // url: `/contacts/${params.id}/`,
                url: `/customers/${params.id}/`,
                method: 'put',
                data: customer
            })
            // .then(function (res) {
            .then(()=>{
                
                // console.log("RESPONSE", response)
                // console.log("ERROR", error)
                    
                if (response.status === 200){
                    navigate('/customers/')
                }
              
            })
         
        } 
    
    }

    return(

    // <Layout>

        <div className="dashboard-main">

            <section className="dashboard-form">
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Edit Customer" dashboardPreTitle="CRM" />


                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <TextField className="custom-input"
                            value={name}
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                                
                            }}
                            // onChange={handleChange}
                            label="Name" 
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />
                        <TextField className="custom-input"
                            value={email}
                            onChange={(e) => {
                                setEmailError(false)
                                setEmail(e.target.value)
                            }}
                            label="Email" 
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" 
                            fullWidth
                            required
                            error={emailError}
                        />

                        <NumberFormat 
                            value = {mobile}
                            onChange={(e) => {
                                setMobileError(false)
                                setMobile(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Mobile" 
                            variant="outlined" 
                            customInput={TextField}
                            format="### (###) ######" mask="_"
                            required
                            error={mobileError}
                        />

                        <TextField className="custom-input"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            label="Address" 
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" 
                            fullWidth
                            // required
                            // error={mobileError}
                        />
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            // defaultChecked=""
                            value={id_type}
                            onChange={(e) => setId_type(e.target.value)}
                            className="custom-input"
                        >
                            <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Citizen ID" />
                            <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Passport" />
                        </RadioGroup>

                        <TextField className="custom-input"
                            value={id_number}
                            onChange={handleIdNumber}
                            // onChange={(e) => {
                            //     setIdNumberError(false)
                            //     setId_number(e.target.value)
                            // }}
                            label="ID/Passport Number" 
                            helperText="Maximum number of characters is 9" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={idNumberError}
                        />

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            // defaultChecked=""
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="custom-input"
                        >
                            <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                            <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                        </RadioGroup>


                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="Date of Birth"
                                value={dt}
                                onChange={(newDate) => {
                                    setDt(moment(newDate).format("YYYY-MM-DD"));
                                  }}
                                renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                            />
                        </LocalizationProvider>


                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary form-buttons" type="submit">
                                Save
                            </button>
                        </div>

                </form>

            </section>
        </div>

        // </Layout>
    )

}

export default CustomerEdit;
