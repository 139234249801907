import React from "react"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import useAxios from "./useAxios";
// import axios from "./authAxios";
import authAxios from "./authAxios";
// import authAxios from "./authAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";

// import Fab from '@mui/material/Fab';
// import AddIcon from '@mui/icons-material/Add';
// import { Card, CardContent } from "@mui/material";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'

// import NumberFormat from "react-number-format";

//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UnitList from "./UnitList";
import UnitTypeList from "./UnitTypeList";
import { useEffect } from "react";
// import UnitTypeNew from "./UnitTypeNew";
// import UnitNew from "./UnitNew";
import RateList from "./RateList";


const Property = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    const params = useParams()
    const navigate = useNavigate()

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    //  Fetch Property
    const { data: property } = useAxios({
        url: `/properties/${params.id}`,
        method: 'get'
    })


    // FETCH PROPERTY UNITS
    // const { response, data, fetchData}  = useAxios();   
    const { response, data, fetchData}  = useAxios();   

    const [units, setUnits] = useState([]);
    // const [didCancel, setDidCancel] = useState(null);
    // let didCancel = false

    async function fetchUnits () {

        try {
            // fetchData({
            //     url: `/unitsbyproperty/?property=${params.id}&page=${page}`,
            //     method: 'get'
            // })
            // // .then(()=>{
            //     if (!didCancel){
            //         console.log("DATA", data)
            //         // console.log("RESPONSE", response)
            //         console.log("NOT CANCELLED", didCancel)

            //         setPageCount(data.page_count)
            //         setUnits(data.results)
            //     }
            //     else(
            //         console.log("CANCELLED", didCancel)
            //     )
            // // })

            // return () => {
                // setActive (false);
            // };

            const response = await authAxios.get(`/unitsbyproperty/?property=${params.id}&page=${page}`);
            setPageCount(response.data.page_count)
            setUnits(Array.from(response.data.results))


        } catch (error) {
            console.error(error);
        }
    }
    
    // FETCH PROPERTY UNIT TYPES 
    const [pageCountUt, setPageCountUt] = useState();
    const [unittypes, setUnitTypes] = useState([]);
    async function fetchUnitTypes () {
        try {
            const response = await authAxios.get(`/unittypes/?property=${params.id}`);
            setPageCountUt(response.data.page_count)
            setUnitTypes(Array.from(response.data.results))

        } catch (error) {
            console.error(error);
        }
    }



    // const [sumInvoices, setSumInvoices] = useState(0)
    let sumInvoices = 0
    let sumReceipts = 0
    let cntUnits = 0

    // let customer = ""
        
    const calcStats = () => {

        // Get number of property units
        // for(let i= 0; i < propertyUnits.length; i++){
        //     cntUnits = cntUnits + 1
        // }

        // const [sumInvoices, setSumInvoices] = useState(0)
        // for(let i= 0; i < txns.length; i++){
            // const invoice = sumInvoices
            // console.log(txns[i].type)
            // if (txns[i].type===10){
            //     sumInvoices = sumInvoices +  parseFloat(txns[i].amount)
            // }
            // if (txns[i].type===20){
            //     sumReceipts = sumReceipts +  parseFloat(txns[i].amount)
            // }
        // }
    }
    calcStats()

    //Bootstrap Tabs
    // const [key, setKey] = useState('profile');
    // const [activeTab, setActiveTab] = useState('');
    const [currentTab, setCurrentTab] = useState('');

    const location = useLocation();

    const [originTab, setOriginTab] = useState(location.state.origintab);


    // const [key, setKey] = useState("");
    const [key, setKey] = useState("profile");


    const setActiveTab = () => {
        // setKey('statement')
        if (originTab === "UNITS"){
            setKey("units")
        }
        if (originTab === "TYPES"){
            setKey("unittypes")
        }
        else {
            setKey("profile")
        }
        console.log("TAB",originTab)
    }



    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };

    const [unitsDrawerOpen, setUnitsDrawerOpen] = useState(false)

    const handleUnitsDrawerOpen = () => {
        setUnitsDrawerOpen(true)
    };

    const handleDrawerClose = () => {
        console.log("DRAWER CLOSE")
        setIsDrawerOpen(false)
    };
    
    // console.log("ACTIVE TAB",activeTab)


    // getActiveTab()

    const [removeBorder, setRemoveBorder] = useState(true);

    //PAGINATION
    // const [loading, setLoading] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(10);
  
    // Change page
    // const paginate = pageNumber => setCurrentPage(pageNumber);
  
    const handlePageChange = (event, value) => {
        setPage(value);
        // console.log("PAGE", page)
        // console.log("VALUE", value)

    };
  


    useEffect (() => {
        fetchUnits()
       
        
    
        // console.log("PAGE", page)

        // setActiveTab()

        // console.log("ORIGIN TAB",originTab)

        // console.log("TAB",key)
        // if (currentTab){
        //     setKey(currentTab)
        //     // console.log("T1", currentTab)
        // }
        // else {
        //     setKey("profile")
        //     // console.log("T2")
    // },[originTab, page, data.page, data.count])
    },[ page ])



    return (
        // <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle={property.name} dashboardPreTitle="Properties" removeBorder={removeBorder} />

                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Address
                                </div>
                                <div>
                                    {property.address}
                                </div>
                            </div>
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Number of Units
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* {cntUnits} */}
                                        {property.units}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Average Length of Stay (Days)
                                    </div>
                                    <div className="dashboard-card-text">
                                        0
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Number of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        0
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Occupancy
                                    </div>
                                    <div className="dashboard-card-text">
                                        0%
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="unittypes" title="Unit Types">
                            <UnitTypeList 
                                unittypes={unittypes} 
                                fetchUnitTypes={fetchUnitTypes}
                                pagecount={pageCountUt}
                                // handlePageChange={handlePageChange} 
                            />
                        </Tab>
                        <Tab eventKey="units" title="Units">
                            <UnitList 
                                units={units} 
                                // fetchUnits={fetchUnits}
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            />
                        </Tab>
                        <Tab eventKey="rates" title="Rates">
                            <RateList 
                                unittypes={unittypes} 
                                fetchUnitTypes={fetchUnitTypes}
                            />
                        </Tab>
                        
                    </Tabs>     

                </section>

            </div>

        // </Layout>

    );
  };
  
  export default Property;