import React from 'react';
import { Drawer } from "@mui/material";

const ReportDrawer = ({children, isDrawerOpen, handleDrawerClose}) => {
    return(
        <Drawer
            variant='temporary'
            // anchor='left'
            anchor="right"
            role='presentation'
            open={isDrawerOpen}
            // open={true}
            onClose={handleDrawerClose}
            className="dashboard-drawer"
            PaperProps={{
                sx: { width: "35%" },
              }}
        >
             
             { children }

        </Drawer>

    )
}

export default ReportDrawer;