// import axios from "axios";
import authAxios from "./authAxios";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import useAxios from "./useAxios";
// import { Axios } from "axios";

// const instance = axios.create();

const useAxiosInterceptor = () => {

  const navigate = useNavigate()
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');

  // const acctkn = localStorage.getItem('accessToken')
  const [accToken, setAccToken] = useState(localStorage.getItem('accessToken'));
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const refreshToken = localStorage.getItem('refreshToken');


  // axios.interceptors.request.use(function (config) {
  authAxios.interceptors.request.use(function (config) {
    // console.log("CONFIG 1", config)

    setAccessToken(localStorage.getItem('accessToken'))


    // Do something before request is sent
    // config.headers.Authorization =  `Bearer ${accessToken}`;
    // axios.defaults.baseURL= process.env.REACT_APP_API_ENDPOINT;
    config.headers.Authorization =  `Bearer ${localStorage.getItem('accessToken')}`;
   
    // setConfig(config)
    return config;
    }, function (error) {
      // Do something with request error
      console.log("Request Error",error)

      return Promise.reject(error);
    }
  );
    

  // Add a response interceptor
  // axios.interceptors.response.use(function (response) {
  authAxios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      // setAccToken(response?.config?.headers.Authorization);
      // console.log("INT SUCCESS RESPONSE", response)
      // console.log("RESPONSE", response)



      setAccToken(response.config.headers.Authorization);
      setResponse(response);

      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // if (error.response || error.config) {

        // console.log("INTERCEPTOR ERROR",error)
        const status = error.response.status
        const originalRequest = error.config

        // console.log("status",status,"url",originalRequest.url,"code",error.response.data.code,"text",error.response.statusText)
        // console.log("RETRY ",originalRequest._retry)
        // console.log("error response",error.response)
        // console.log("error message",error.message)
        // console.log("error request",error.request)
        // console.log("Response Data",error.response.data)

        // console.log("error config",error.config)
        // console.log("original request url",originalRequest.url)


        // Check Token validity status
        if (status === 401 && originalRequest.url === "/token/refresh/"){
        // if (status === 401 && originalRequest._retry){
          // navigate('/login')
          navigate('/login', { state: { from: originalRequest.url }, replace: true })

          console.log("STOP LOOP")
          return Promise.reject(error)
        }

        // if (refreshToken){

        // if (status === 401 && error.response.data.code === "token_not_valid" && error.response.statusText === "Unauthorized"){
        if (status === 401 && !originalRequest._retry){
            originalRequest._retry = true;
            authAxios.post('/token/refresh/', {

            // axios.post('/token/refresh/', {
            // useAxios.post('/token/refresh/', {
                refresh: refreshToken 
            })
            .then(res => {
            // setToken(res.data);
            setAccessToken(res.data.access);
            localStorage.setItem('accessToken', res.data.access)
            authAxios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access;
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access;
            originalRequest.headers.Authorization = 'Bearer ' + res.data.access;

           
            // return axios.request(originalRequest)
            return authAxios.request(originalRequest)

            
            // .then(response=>{
            //   console.log("REFRESH",response)
            //   setLeads(response.data);
            // })

            // return axios(originalRequest);
            // return axios.get(originalRequest.url);
            // return instance.get(originalRequest.url);


            })

            .catch(function (error) {
              console.log("INTERCEPTOR ERR", error)
              

              if (status === 401 && originalRequest._retry){
                navigate('/login', { state: { from: originalRequest.url }, replace: true })
              }

            });
        }

        
        if (status === 400){
            setError(error.request.responseText);

        }
        else if (status === 401){
            setError(status +" " + error.response.statusText + " - " + error.response.data.detail);
        }
        else {
            setError(status + " " + error.response.statusText);
        }

      return Promise.reject(error);
    }

  );

  function updToken(){
    setAccToken(localStorage.getItem('accessToken'))
    setError("")
    return accToken
  };

  // axios.interceptors.request.eject();
  // axios.interceptors.response.eject();
  authAxios.interceptors.request.eject();
  authAxios.interceptors.response.eject();
  return {response, error, accToken };
}
 
export default useAxiosInterceptor;