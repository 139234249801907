import React, { useEffect } from "react"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import useAxios from "./useAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
// import { Card, CardContent } from "@mui/material";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const AppSettings = () => {
    //Bootstrap Tabs
    const [key, setKey] = useState('app');
    // const [activeKey, setActiveKey] = useState('profile');

    const [removeBorder, setRemoveBorder] = useState(true);

    const clientID = localStorage.getItem('clientID');

    //  Fetch Client
    const { data: clientprofiles } = useAxios({
    // const { data: clientprofiles } = useAxios({
        // url: `/clientprofiles/${clientID}`,
        url: "/clientprofiles",
        method: 'get'
    })  
    const client = clientprofiles.find(function(clientprofile) {
        return (clientprofile.client === parseInt(clientID));
    });

    // console.log("CP",clientprofiles)
    // console.log("CLIENT ID",clientID)
    // console.log("CLIENT",client)


    return(


    <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle="Settings" dashboardPreTitle="Settings" removeBorder={removeBorder}  />

                    {client && 

                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="app" title="App">
                            <div className='dashboard-card' id="settings-card">
                                <div className="dashboard-card-pretitle">
                                        Client Name
                                </div>
                                <div className="dashboard-card-text">
                                        {client.client_name}
                                </div>
                            </div>
                            <div className='dashboard-card' id="settings-card">
                                <div className="dashboard-card-pretitle">
                                    Next Invoice Number
                                </div>
                                <div className="dashboard-card-text">
                                    {client.invoice_no}
                                </div>
                            </div>
                            <div className='dashboard-card' id="settings-card">
                                <div className="dashboard-card-pretitle">
                                    Next Receipt Number
                                </div>
                                <div className="dashboard-card-text">
                                    {client.receipt_no}
                                </div>
                            </div>

                            <div className='dashboard-card' id="settings-card">
                                <div className="dashboard-card-pretitle">
                                    Debtors Control Account
                                </div>
                                <div className="dashboard-card-text">
                                    {client.debtors_account_name}
                                </div>
                            </div>
                            <div className='dashboard-card' id="settings-card">
                                <div className="dashboard-card-pretitle">
                                    Revenue Account
                                </div>
                                <div className="dashboard-card-text">
                                    {client.revenue_account_name}
                                </div>
                            </div>
                        </Tab>
                        
                        
                    </Tabs>     
                    }

                    <Fab className="fab" color="primary" aria-label="add">
                    
                    <AddIcon />
                </Fab>

                </section>
                

            </div>

        </Layout>
    )

}

export default AppSettings;